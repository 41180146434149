import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {Route, Switch, NavLink, Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";

import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';
import { white } from 'color-name';
import { runInThisContext } from 'vm';
import filter_icon_143777 from '../../resources/img/filter_icon.svg'
import CommonPopup from '../modules/CommonPopup';
import id from 'date-fns/esm/locale/id/index.js';
import { relative } from 'path';
import ConfirmDialogV2 from "../modules/dialogs/ConfirmDialogV2";
import BasicDialogV2 from "../modules/dialogs/BasicDialogV2";

import round_loading_bar from '../../resources/img/round_loading_bar.gif';
import SelectBoxV2 from '../modules/common/SelectBoxV2';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
import * as StyleUtil from '../modules/utils/StyleUtil';
import PopupFrame from '../modules/popup/PopupFrame';
import CheckBox from '../modules/common/CheckBox';
import Icon from '../modules/common/Icon';
import { List, arrayMove } from 'react-movable';

import FileDownload from 'js-file-download';
import * as CookieUtil from '../modules/utils/CookieUtil';
import * as CommonDownloadManager from '../modules/CommonDownloadManager';
import preview_icon from '../../resources/img/preview_icon.svg';
import icn_download from '../../resources/img/download.svg';
import icn_delete from '../../resources/img/icn_delete.svg';
import icn_clone from '../../resources/img/icn_clone.svg';
import Button from '../modules/common/Button';

/** 셀렉트 박스 */
import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

let window_scrollTop; // 스크롤 위치

var none_detail_click = false; // 테이블 위 요소 클릭시 상세보기로 넘어가지 않도록 함
var is_reload = false;
var is_mirror_button_click = false;
var mirror_banner_modal_v2_width;
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

var object_domain = "https://"+document.domain
var object_port = window.location.port;
if (document.domain.indexOf("127.0.0.1") >= 0 && object_port == "3000") {
    object_domain = "http://127.0.0.1:8092"
} else if (object_port != '') {
    object_domain = object_domain + ":" + object_port
}

let is_test_use = "";
let test_yn = "";
let req_host = window.location.protocol + '//' + document.domain + (window.location.port != '' ?  ':'+window.location.port : '');
if (document.domain.indexOf("127.0.0.1") >= 0 || document.domain.indexOf("otdeal.me") >= 0) {
    is_test_use = "&is_test_use=Y";
    test_yn = "Y";
}

export class EventList extends React.Component {
	state = {
			iframe_html_id: "",
			iframe_page_id: "",
			event_type: [],
			event_mobile_type: [],
			iframe_html_id_list: [],
			iframe_page_id_list: [],
			event_list: [],
			checked_auto_event_list: [], // 선택된 배너 목록
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,
			isCreate: false,
			eventDisplayYn: "Y",
			is_ten_cnt_error:false,
			is_thirty_cnt_error:false,
			display_yn: '',
			item_user_type: '',
			reg_user_seq: '',
			mod_user_seq: '',
			my_banner_yn: 'N',

			/** 배너 순서변경 */
			show_order_popup : false,
			order_iframe_page_id : null,
			order_iframe_html_id_list : [],
			order_iframe_html_id : null,
			search_iframe_page_id : null,
			search_iframe_html_id : null,
			search_iframe_html_id_list : [],
			show_order_popup_loading_bar : false,
			order_popup_event_list : [],
			order_event_idx : [],
			order_list_is_modified : false,

			//공통 팝업
			common_popup1: false,			//팝업 출력 여부에 사용
			common_popup1_content: '',
			common_popup1_button_list: [],
			delete_dialog_show_state : false, // 삭제 여부 물어보기
			delete_dialog_content: "",
			delete_dialog_button_list: [],
			
			// 비연동형 값 : 09900
			solution_type_cd : "",

			// 캡처 (가능)상태
			is_download: true,
			is_banner_download: true,
			download_event_seq: "",

			// 파일명 규칙 설정
			file_name_setting_yn : "",
			file_name_setting_list: [],
			w_file_name_setting_list: [],
			h_file_name_setting_list: [],
			file_name_default_setting_list: [],
			w_file_name_default_setting_list: [],
			h_file_name_default_setting_list: [],
	};

	handleText = (e, key) => {
	    // computed property
		this.setState({[key]: e.target.value});
	}

	componentWillMount() {
		let solution_type_cd = CookieUtil.getCookie("solution_type_cd");
		// alert(solution_type_cd);
		this.setState({
			solution_type_cd : solution_type_cd
			, iframe_page_id: solution_type_cd == "09900" ? "robomd_main_page" : null
		})
	}
	
	componentDidMount(e) {
		// 비연동 형일 경우 최초 robomd_main_page
		if (this.state.solution_type_cd == "09900") {
			this.getRobomdPageHtmlId("html", this.state.iframe_page_id);
		}

		mirror_banner_modal_v2_width = $(".mirror_banner_modal_v2").width();

		// alert("componentDidMount")
		$("#start_date").attr("readOnly", "true");
		$("#end_date").attr("readOnly", "true");

		$(".mirror_banner_modal_v2").hide();

		$("#modalBack").css("display","none");

		let this_component = this;
		$(document).ready(function(){
			$(window).resize(function(){
				this_component.onLoadOrderChangeTable();
				this_component.setHeight();
			});
		});
		
		// $("select").focus(function() {
        //     $(this).parent().parent().children(".input_text_title").css("color", "#10CFC9");
        // })
        // $("select").blur(function() {
        //     $(this).parent().parent().children(".input_text_title").css("color", "#333333");
        // })

		// console.log(localStorage.getItem ('state'))
		console.log(this.state.event_type);
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
			.then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.event_type = [];
				json.event_list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				this.setState(json, () => {
					this.setState({
						startIdx : parseInt(localStorage.getItem("startIdx"))
					}, () => {
						// this.getCafe24Code("16000");
						this.loadCommonCodeForAutoEvent("16000", "iframe_page_id_list");
						this.getEventList();
					})
					localStorage.clear();
				});
				// this.getRobomdPageHtmlId("page", "");

				// var json = JSON.parse(localStorage.getItem ('state'));
				// // console.log(json);
				// json.event_type = [];
				// json.event_list = [];
				// json.start_date = Date.parse(json.start_date_format);
				// json.end_date = Date.parse(json.end_date_format);
				// this.setState(json);
				// console.log(this.state.event_type);
				// this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
				// localStorage.clear();
				// this.getEventList();
			});
		} else {
			// this.getRobomdPageHtmlId("page", "");
			// this.getCafe24Code("16000");
			this.loadCommonCodeForAutoEvent("16000", "iframe_page_id_list");
			this.getEventList();
		}

		http.get("/rest/common/isOtdealUser")
		.then(res => {
			this.setState({isOtdealUser: res.data.isOtdealUser});
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });

		 $(window).resize(this.setHeight)

		 $('.mirror_banner_modal_v2').css('visibility', 'hidden');
	}

	//배너 html_id 조회
	async getRobomdPageHtmlId(type, page_id) {
		const res = await http.get("/rest/common/robomd/PageHtmlId?shop_no="+"1"+"&type="+type+"&iframe_page_id="+page_id);
        if(res.data != null) {
			if (type == "html") {
				is_reload = true;
				this.setState({
					iframe_html_id_list: [{iframe_html_id:"", iframe_html_name:"전체"}, ...res.data.html_id_list],
					iframe_html_id : "",
				});
			} else if (type == "page") {
				// this.setState({iframe_page_id_list: res.data.page_id_list});
			}
        }
	};

	componentDidUpdate(e) {
		// console.log("######### componentDidUpdate ##########")
		if (is_reload) {
			this.onClickSearchListButton();
			is_reload = false;
		}
		if (is_mirror_button_click) {
			this.setHeight();
			$(".mirror_banner_v2_mobile_banner").html('<object class="robomd_banner_p" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+this.state.mirror_event_seq+'&platform_type=m&mirror_type=18002" type="text/html" style="width:100%;height:100%; background: white;"></object>');
			$(".mirror_banner_v2_pc_banner").html('<object class="robomd_banner_m" type="text/html" data="'+object_domain+'/admin_banner.html?event_seq='+this.state.mirror_event_seq+'&platform_type=p&mirror_type=18002" type="text/html" style="width:100%;height:100%; background: white;"></object>');
			is_mirror_button_click = false;
		}
	}

	setHeight = () => {
		$(".mirror_banner_modal_v2").show();

		let pc_width_height = this.state.event_banner_pc_width_height;
		let mobile_width_height = this.state.event_banner_mobile_width_height;
		
		if(Number(this.state.event_banner_pc_width_height) <= 1 && Number(this.state.event_banner_mobile_width_height) > 1){
			pc_width_height = this.state.event_banner_mobile_width_height;
			mobile_width_height = this.state.event_banner_pc_width_height;

		} else if(Number(this.state.event_banner_pc_width_height) <= 1 || Number(this.state.event_banner_mobile_width_height) > 1){
			if(this.state.event_banner_pc_width_height){
				pc_width_height = this.state.event_banner_pc_width_height;
				mobile_width_height = this.state.event_banner_pc_width_height;
			} else{
				pc_width_height = this.state.event_banner_mobile_width_height;
				mobile_width_height = this.state.event_banner_mobile_width_height;
			}
		}

        const dbcw = document.body.clientWidth;

        const mirror_banner_modal_v2_width = dbcw / 2;

        // 영역 가로 사이즈 설정 / 영역 margin 설정(가로형 세로형 사이 간격)
        if(dbcw > 795) { // 800인 모바일 사이즈에 맞춰 변화하도록 설정 (5px 만큼 차이 있음)
            let mWidth = (mirror_banner_modal_v2_width / 6.2) * 2.2;
            let pWidth = (mirror_banner_modal_v2_width / 6.2) * 4;
            
            $(".mirror_banner_v2_mobile_banner").css("width", `${mWidth - 40}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${pWidth - 50}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "10px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "0px");
        } else {
            let mbmw = $(".mirror_banner_v2_body").width();
            $(".mirror_banner_v2_mobile_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner").css("width", `${mbmw - 5}px`);
            $(".mirror_banner_v2_pc_banner_area").css("margin-right", "0px");
            $(".mirror_banner_v2_pc_banner_area").css("margin-bottom", "10px");
        }

        const dbch = window.innerHeight;
		const maxHeight = dbch * 0.6 - 43; //0.6은 임의의 값, 43은 세로형 or 가로형 텍스트 칸 크기

        /* 세로형 베너 세로 사이즈 너무 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let mh = $(".mirror_banner_v2_mobile_banner").width() / mobile_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(mh > maxHeight) {
			mh = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = mh * mobile_width_height;
            $(".mirror_banner_v2_mobile_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_mobile_banner").css({"height": mh+"px"});

        /* 가로형 베너 세로 사이즈 커지는 현상 방지 */
        // 설정된 가로 사이즈를 기준으로 비율 계산을 통해 세로 사이즈 구하기
        let ph = $(".mirror_banner_v2_pc_banner").width() / pc_width_height;
        // 비율에 맞게 설정된 세로사이즈가 maxHeight를 넘은 경우의 처리 
		if(ph > maxHeight) {
			ph = maxHeight; // 세로 사이즈를 max값으로 설정
            // 세로 사이즈를 기준으로 비율 계산으로 통해 가로 사이즈 구하기
            const width = ph * pc_width_height;
			$(".mirror_banner_v2_pc_banner").css("width", width+"px");
		}
        $(".mirror_banner_v2_pc_banner").css({"height": ph+"px"});
    }

	onComponentChange = e => {
		// console.log("######### onComponentChange ##########")
	}

	componentWillUnmount = e => {
		// console.log("######### componentWillUnmount ##########")
	}

	//공통코드 조회
	async getCafe24Code(upper_code_cd) {
		const res = await http.get( "/rest/common/cafe24Code"
									+"?upper_code_cd="+upper_code_cd);
		if (upper_code_cd == "16000") {
			this.setState({iframe_page_id_list: res.data})
		}
	};

	loadCommonCodeForAutoEvent(upper_code_cd, stateName) {
		http.get("/rest/common/cafe24AutoEventCode?upper_code_cd="+upper_code_cd)
		.then(res => {
            console.log(res.data);
            this.setState({[stateName]: res.data}); 
        })
    }
	
	//1. 목록 조회
	async getEventList() {

		// 이벤트 대상 리스트 초기화
		this.setState({
			checked_auto_event_list: [],
		});
		
		// console.log($("#event_type option:selected").val());
		// console.log($("#event_mobile_type option:selected").val());

		var iframe_html_id = ""
		var iframe_page_id = ""
		if (this.state.iframe_html_id != null) {
			iframe_html_id = this.state.iframe_html_id;
		}
		if (this.state.iframe_page_id != null) {
			iframe_page_id = this.state.iframe_page_id;
		}
		await http.get("/rest/common/event/List?start_date="+this.state.start_date_format+"&end_date="+this.state.end_date_format+"&title="+this.state.title
		+"&order_type="+this.state.sorting+"&order_gubun="+this.state.orderGubun+"&iframe_html_id="+iframe_html_id
		+"&startidx="+this.state.startIdx+"&rowcnt="+this.state.rowcnt
		+"&event_type_cd="+""+"&event_mobile_type_cd="+""+"&iframe_page_id="+iframe_page_id+"&shop_no=1"
		+"&display_yn="+this.state.display_yn+"&item_user_type="+this.state.item_user_type
		+"&mod_user_seq="+this.state.mod_user_seq
		+"&reg_user_seq="+this.state.reg_user_seq
		+"&my_banner_yn="+this.state.my_banner_yn)
		.then(res => {
			if(res.data.err_code == 401) {
				window.location.href = "/logout";
			} 

			this.setState( 
				{
					event_type : res.data["eventTypeList"]
					, event_mobile_type: res.data["eventMobileTypeList"]
				} 
			);
			if(res.data["list"] != null) {
				this.setState( {
					event_list : res.data["list"],
					file_name_setting_yn : res.data["list"].file_name_setting_yn,
				});
			}
			if (res.data["totalCnt"] != null) {
				this.setState( {totalCnt : res.data["totalCnt"]} );
			} else {
				this.setState( {totalCnt : 1} );
			}
			// console.log ("===== res =====");
			// console.log(res);
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
		 window.scrollTo({left:0, top:0});
		 $(".banner_list_table_area_parent").scrollLeft(0);
		 $(".banner_list_table_area_parent tbody").scrollTop(0);
    }
	
	//2. 상세 조회
	handleClick = (event_seq, event_promotion_type_cd, event_mod_user_seq) => {
		if (none_detail_click) {
			none_detail_click = false;
			return false;
		}
		if (event_promotion_type_cd == "19002") {
			this.props.history.push(
				{
					pathname: `/shop/cafe24/promotion/form`,
					search:`?event_seq=`+event_seq,
					state: {prevPath: window.location.href}
				}	
			)	
		} else {
			var search = ""
			if (event_mod_user_seq == -2 ) {
				search = `?event_seq=`+event_seq+`&sample_yn=Y`
			} else {
				search = `?event_seq=`+event_seq
			}
			this.props.history.push(
				{
					pathname: `/shop/cafe24/event/form`,
					search:search,
					state: {prevPath: window.location.href}
				}	
			)	
		}
			
		// `/shop/cafe24/event/form?event_seq=`+event_seq);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};

	handleChangeType = (e) => {
		this.setState({event_type_cd : e.target.value});
	};
	
	handleChangeMobileType = (e) => {
		this.setState({event_mobile_type_cd : e.target.value});
	};

	handleChangePageId = (e) => {
		this.setState({iframe_page_id : e.target.value});
		this.getRobomdPageHtmlId("html", e.target.value);
		if (e.target.value == "") {
			this.setState({iframe_html_id : ""});
		}
	};
	handleChangeHtmlId = (iframe_html_id) => {
		is_reload = true;
		this.setState({iframe_html_id : iframe_html_id});

	};
	
	// 3. 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC") 
			this.state.sorting = "DESC";
			else
			this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		// alert(this.state.orderGubun);
		// alert(this.state.sorting);
		this.getEventList()
	};

	//4. 조회 버튼 클릭
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getEventList();
	};

	//4. 달력 클릭
	handleChangeStartDate = date => {
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
		is_reload = true;
	};
	handleChangeEndDate = date => {
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ parseInt(date.getMonth()+1) +"-"+date.getDate(),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
		is_reload = true;
	};
	handleInitDate = name => {
		if (name == "start_date") {
			this.setState({
				start_date_format: "",
				start_date: ""
			});
		} 
		if (name == "end_date") {
			this.setState({
				end_date_format: "",
				end_date: ""
			});
		}
		is_reload = true;
	};

	//5. 엔터키 검색
	handleSearchList = e => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getEventList();
	  }

	//6.페이징
	handlePageChange(pageNumber) {
		this.setState({activePage: pageNumber
						, checked_auto_event_list: []
		});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		this.getEventList();
		// this.setState({
		// 	checked_auto_event_list: [],
		// });
	  }  

	  componentWillReceiveProps(nextProps, nextContext) {
		// console.log(nextProps);
		this.getEventList(nextProps.match.params.id);
	  }

	  onClickCreateOtdealBanner = e => {
		this.setState({isCreate: true});  
		http.get("/rest/common/otdeal/event/create")
		.then(res => {
            alert("생성 되었습니다.")
            window.location.reload();
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
		 });
	  }

	  onClickStartDateInputBox = e => {
		$("#start_date").removeAttr("readOnly");
		$("#start_date").click();
		$("#start_date").attr("readOnly", "true");
	  }

	  onClickEndDateInputBox = e => {
		$("#end_date").removeAttr("readOnly");
		$("#end_date").click();
		$("#end_date").attr("readOnly", "true");
	  }
	  
	  // 한개씩 진열 변경
	  onClickEventDisplay = (e, event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, event_period, idx, display_yn) => {
		// alert(iframe_page_id); 
		// alert(iframe_html_id); 
		
		none_detail_click =  true;
		if (Number(mod_user_seq) == -2) {
			alert("샘플을 진열 하려면 배너 수정이 필요합니다.");
			return false;
		}

		this.stroeOrSettingCnt(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, event_period, idx, display_yn);
	  }

	onClickMirrorButton = (e, event_seq, event_banner_pc_width_height, event_banner_mobile_width_height, event_type_cd, event_mobile_type_cd) => {


		console.log(this.state.event_list)
		none_detail_click = true;
		is_mirror_button_click = true;
		$('.mirror_banner_modal_v2').css('visibility', 'visible');
		// if (event_banner_pc_width_height == null ) {
		// 	event_banner_pc_width_height = 1.6
		// }
		// if (event_banner_mobile_width_height == null ) {
		// 	event_banner_mobile_width_height = 0.8
		// }

		this.setState({event_banner_pc_width_height: event_banner_pc_width_height
			, event_banner_mobile_width_height: event_banner_mobile_width_height
			, mirror_event_seq: event_seq
			, event_type_cd: event_type_cd
			, event_mobile_type_cd: event_mobile_type_cd});
	}

	onClickMirrorBannerOff = (e, state) => {
		$(".mirror_banner_modal_v2").hide();
		$('.mirror_banner_modal_v2').css('visibility', 'hidden');
	}
	
	onClickBtnMobileFilterOpen = (e) => {
		if($(".list_filter_area").css("display") == "block") {
			$(".list_filter_area").css("display", "none");
		} else {
			$(".list_filter_area").css("display", "block");
		}
		
	}

	onClickDisplayYnFilter = e => {
		$(".list_filter_display_yn").removeClass("list_filter_type_text_on");
		$(".list_filter_display_yn").addClass("list_filter_type_text");
		$("#"+e.target.id).addClass("list_filter_type_text_on");

		is_reload = true;
		if(e.target.id == "list_filter_display_all") {
			this.setState({display_yn: ""})
		} else if(e.target.id == "list_filter_display_y") {
			this.setState({display_yn: "Y"})
		} else if(e.target.id == "list_filter_display_r") {
			this.setState({display_yn: "R"})
		} else if(e.target.id == "list_filter_display_n") {
			this.setState({display_yn: "N"})
		}
	}

	onClickDisplayUserFilter = e => {
		$(".list_filter_display_user").removeClass("list_filter_type_text_on");
		$(".list_filter_display_user").addClass("list_filter_type_text");
		$("#"+e.target.id).addClass("list_filter_type_text_on");

		is_reload = true;
		if(e.target.id == "list_filter_all_user") {
			this.setState({item_user_type: ""})
		} else if(e.target.id == "list_filter_only_user") {
			this.setState({item_user_type: "U"})
		} else if(e.target.id == "list_filter_none_user") {
			this.setState({item_user_type: "N"})
		} else if(e.target.id == "list_filter_target_view") {
			this.setState({item_user_type: "T"})
		} else if(e.target.id == "list_filter_default") {
			this.setState({item_user_type: "D"})
		}
	}

	onClickAutoBannerYnFilter = e => {
		$(".list_filter_auto_banner").removeClass("list_filter_type_text_on");
		$(".list_filter_auto_banner").addClass("list_filter_type_text");
		$("#"+e.target.id).addClass("list_filter_type_text_on");

		is_reload = true;
		if(e.target.id == "list_filter_all_banner") {
			this.setState({
				reg_user_seq: "", // 초기화
				mod_user_seq: "", // 전체 조회
				my_banner_yn: "N", // 마이 배너 X
			}) 
		} else if(e.target.id == "list_filter_auto_banner") {
			this.setState({
				reg_user_seq: "-1", // 자동 배너
				mod_user_seq: "", // 전체 조회
				my_banner_yn: "N", // 마이 배너 X
			})
		} else if(e.target.id == "list_filter_sample_banner") {
			this.setState({
				reg_user_seq: "", // 초기화
				mod_user_seq: "-2", // 샘플 배너
				my_banner_yn: "N", // 마이 배너 X
			})
		} else if(e.target.id == "list_filter_my_banner") {
			this.setState({
				reg_user_seq: "", // 초기화
				mod_user_seq: "", // 전체 조회
				my_banner_yn: "Y", // 마이 배너 O
			}) 
		} else if(e.target.id == "list_filter_manual_banner") {
			this.setState({
				reg_user_seq: "", // 초기화
				mod_user_seq: "0", // -1과 -2가 아닌 나머지를 조회하고 싶을 때는 0 으로 넣어주세요.
				my_banner_yn: "N", // 마이 배너 X
			}) 
		}
	}

	stroeOrSettingCnt(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, event_period, idx, display_yn) {
        var is_free_finish = false;
		var is_order = false;
		var is_install = false;
		http.get("/rest/shop/stroeOrSettingCnt?iframe_page_id="+iframe_page_id+"&iframe_html_id="+iframe_html_id)
        .then(res => {
			if (res.data.freeDatePeriod != null) {
				if(res.data.freeDatePeriod.is_finish == "1") {
					if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
						is_order = true;
					}
					is_free_finish = true;
				} else {
					if (res.data.stroeOrSettingCnt.banner_operateed_yn == 'Y') {
						is_free_finish = true;
						is_order = true;
					} else {
						is_free_finish = false;
					}
				}

				// 설치 여부 확인
                if (res.data.bannerInstallInfo != null) {
                    if (res.data.bannerInstallInfo.install_yn == "Y") {
                        is_install = true;
                    }
                }
            }

			if (state == "Y") {
				if(is_install != true) {
					alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
					return false;
				} 
				if(is_free_finish == true) {
					//미결제시 진열 불가
					if(is_order != true) {
						alert("자동 진열 기능은 멤버십 결제 후 이용하실 수 있습니다. 결제 후 진열하기로 변경해 주세요.");
						//alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
						return false;
					}
				}
				// 2022.01.26 : 무료체험기간 중 진열 가능
				//else {
					// if(is_order != true) {
                    //     // alert("무료기간 중에는 진열로 변경 할 수 없습니다.");
                    //     alert("배너를 진열하려면 고객님 쇼핑몰에 스크립트를 설치해야 합니다. 화면 왼쪽 아래 채팅 아이콘을 눌러 로보MD팀에게 설치를 신청해 주세요.");
					// 	return false;
					// }
				//}
			}

			//alert("state : " + state + " / display_yn : " + display_yn)
			// 배너를 미진열 상태로 변경하시겠습니까?
			if(state == "Y") {
				if((new Date(start_date) <= new Date() && new Date() <= new Date(end_date)) 
					|| new Date() < new Date(start_date)
				) {
					this.setState({
						common_popup1: true,
						common_popup1_content: "배너를 진열 상태로 변경하시겠습니까?",
						common_popup1_button_list: [
							{
								title: "네",
								type: "1",
								event_method: () => {
									this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, "N", idx);		//forced_yn : Y (기간외 강제 변경)
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							},
							{
								title:"아니요",
								type:"0",
								event_method: () => {
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							}
						]
					});
					//this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, mod_user_seq, "N", idx);	//forced_yn : N (기간내 변경)
				} else {
					this.setState({
						common_popup1: true,
						common_popup1_content: event_period + "일 동안 자동 연장되어 진열됩니다.",
						common_popup1_button_list: [
							{
								title: "네",
								type: "1",
								event_method: () => {
									this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, "Y", idx);		//forced_yn : Y (기간외 강제 변경)
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							},
							{
								title:"아니요",
								type:"0",
								event_method: () => {
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							}
						]
					});
					//alert("진열 기간을 먼저 변경해 주세요.");
					// if (window.confirm(event_period + "일 동안 자동 연장되어 진열됩니다.")) {
					// 	this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, mod_user_seq, "Y", idx);		//forced_yn : Y (기간외 강제 변경)
					// }
				}
			} else {
				if (display_yn === "R") {
					this.setState({
						common_popup1: true,
						common_popup1_content: "배너의 예약 상태를 변경하시겠습니까?<br/>원하시는 상태를 골라 주세요.<br/>(변경을 취소하시려면 닫기 X 버튼을 눌러 주세요.)",
						common_popup1_button_list: [
							{
								title: "바로 진열하기",
								type: "1",
								event_method: () => {
									this.eventChangeDisplay(event_seq, name, "Y", start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, "Y", idx);		//forced_yn : N (기간내 변경)
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							},
							{
								title:"미진열로 변경하기",
								type:"0",
								event_method: () => {
									this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, "N", idx);		//forced_yn : N (기간내 변경)
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							}
						]
					});
				} else {
					this.setState({
						common_popup1: true,
						common_popup1_content: "배너를 미진열 상태로 변경하시겠습니까?",
						common_popup1_button_list: [
							{
								title: "네",
								type: "1",
								event_method: () => {
									this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, "N", idx);		//forced_yn : N (기간내 변경)
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							},
							{
								title:"아니요",
								type:"0",
								event_method: () => {
									//팝업 닫기
									this.setState({ common_popup1: false })
								}
							}
						]
					});
				}
				//this.eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, mod_user_seq, "N", idx);		//forced_yn : N (기간내 변경)
			}
		})
        .catch(error => {
            alert(error); 
        });
    }

	// 한개씩 진열 변경 - 저장
	eventChangeDisplay(event_seq, name, state, start_date, end_date, iframe_page_id, iframe_html_id, item_user_type, reg_user_seq, mod_user_seq, forced_yn, idx) {

		// alert(event_seq + " / " + name + " / " + state + " / " + start_date + " / " + end_date + " / " + iframe_page_id + " / " + iframe_html_id + " / " + item_user_type + " / " + mod_user_seq + " / " + forced_yn + " / " + idx);
		// return false;

		http.post("/rest/common/event/change/display"
						+"?display_yn="+state+"&event_seqs="+[event_seq]
						+"&shop_no="+1
						+"&page_id="+iframe_page_id
						+"&iframe_html_id="+iframe_html_id
						+"&item_user_type="+item_user_type
						+"&reg_user_seq="+reg_user_seq
						// +"&mod_user_seq="+mod_user_seq /* 자동 배너 기준이 mod_user_seq에서 reg_user_seq로 바뀌면서 주석처리됨 */
						+"&forced_yn="+forced_yn
				)
			.then(res => {
				// console.log ("===== res =====");
				// console.log(res);
				if (res.data.result) {
					//리스트 조회
					this.getEventList();
					// 시작일자가 미래인 경우, 예약중 상태로 표시
					// console.log(new Date() < new Date(res.data.start_date))
					// if (new Date() < new Date(res.data.start_date) && state == "Y")
					// {
					// 	state = "R"
					// }
					// //alert("변경 되었습니다.");
					// // window.location.reload(true);
					// // if (res.data.start_date != "" && res.data.end_date != "") {
					// 	let events = [...this.state.event_list];
					// 	let event = {
					// 		...events[idx],
					// 		display_yn: state,
					// 		start_date : res.data.start_date,
					// 		end_date : res.data.end_date,
					// 		mod_date : res.data.mod_date,
					// 	}
					// 	events[idx] = event;
					// 	this.setState({event_list : events});
					// // }
					// this.setState({[name]: state});
					// this.setState({is_ten_cnt_error: false});
					// this.setState({is_thirty_cnt_error: false});
				} else {
					if(res.data.err_msg != null) {
						alert(res.data.err_msg)
					}
					if (res.data.err_code == 401) {
						window.location.href="/logout";
					} else if (res.data.err_code == -10) {
						alert("로보배너는 10개까지 진열 가능합니다.");
						this.setState({is_ten_cnt_error: true});
					} else if (res.data.err_code == -30) {
						alert("자동배너는 30개까지 진열 가능합니다.");
						this.setState({is_thirty_cnt_error: true});
					}
				}
			})
			.catch(error => {
				// console.log ("===== error =====");
				// console.log (error);
				// console.log ("===== error request =====");
				// console.log(error.request);
			});
	}

	// 배너 목록 전체 선택
	checkAllAutoEventListOnChange(e) {
		this.setState({
			checked_auto_event_list: e.target.checked ? JSON.parse(JSON.stringify(this.state.event_list)) : [],
		});
	}

	// 배너 목록 개별 선택
	checkOneAutoEventListOnChange(e, event) {
		const {event_list, checked_auto_event_list} = this.state;

		if(e.target.checked){ // 체크되는 상태라면 기존 체크 리스트에 값 추가
            this.setState({ checked_auto_event_list : [...checked_auto_event_list, event_list.find(obj => obj.event_seq == event.event_seq)] });
        } else{ // 체크가 해제되는 상태라면 기존 체크 리스트에서 값 제거
            this.setState({ checked_auto_event_list : checked_auto_event_list.filter(obj => obj.event_seq != event.event_seq) });
        }
	}

	/** 캡처되는 실제 사이즈 계산 */
    getCaptureSize(type, banner_ratio){
        let banner_width = 0;
        let banner_height = 0;
        
        banner_width = 1000 * banner_ratio.org_width_height;
        banner_height = 1000;

        if (banner_width > 10000 || banner_height > 10000) {
            if(type == "pc") {
                    banner_width = 10000
                    banner_height = 10000 / banner_ratio.org_width_height;
            } else {
                    banner_width = 10000 / banner_ratio.org_width_height;
                    banner_height = 10000
            }
        }
        banner_ratio["banner_width"] = banner_width;
        banner_ratio["banner_height"] = banner_height;

        return banner_ratio;
    }

	getMultiBannerDownloadFileName = (event, type) => {
		let _file_name = "";

		// 파일명 규칙 설정 여부
		if (event.file_name_setting_yn == "Y") {     // 파일명 규칙 설정이 "Y"인 경우 (파일명 규칙 설정이 "직접 설정"인 경우)

			if (this.state.file_name_setting_list != null && this.state.file_name_setting_list.length > 0) {    // 파일명 규칙 설정 목록이 존재하는 경우

				// 저장된 파일명 규칙 설정에 맞게 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
				this.state[type + "_file_name_setting_list"].map((obj, idx) => {
					_file_name += String(this.getFileName(event, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

					if (idx + 1 < this.state[type + "_file_name_setting_list"].length) {
						_file_name += "_";
					}
				});

			} else {    // 파일명 규칙 설정 목록이 존재하지 않는 경우

				// 파일명 규칙 기본 설정으로 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
				if(this.state[type + "_file_name_default_setting_list"] != null && this.state[type + "_file_name_default_setting_list"].length>0){
					this.state[type + "_file_name_default_setting_list"].map((obj, idx) => {
						_file_name += String(this.getFileName(event, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

						if(idx+1 < this.state[type + "_file_name_default_setting_list"].length) {
							_file_name += "_";
						}
					});
				}
			}

		}else if(event.file_name_setting_yn == "N") {      // 파일명 규칙 설정이 "N"인 경우 (파일명 규칙 설정이 "기본 설정"인 경우)

			_file_name = event.title;
		}

		return _file_name;
	}

	// 배너 목록 다중 캡처
	async fn_multiCapture() {
		const {checked_auto_event_list} = this.state;
		let req_auto_event_list = JSON.parse(JSON.stringify(checked_auto_event_list));
		let dateString = new Date().getFullYear() + ("0" + parseInt(new Date().getMonth()+1)).slice(-2) + ("0" + parseInt(new Date().getDate())).slice(-2) + ("0" + parseInt(new Date().getHours())).slice(-2) + ("0" + parseInt(new Date().getMinutes())).slice(-2) + ("0" + parseInt(new Date().getSeconds())).slice(-2)
		let file_name = "배너목록_" + dateString;
		let mirror_type = "18002";      // 18001(미리보기), 18002
		let req_list = [];
		let download_type = "is_download";

		if (this.state.is_download) {
			if (req_auto_event_list != null && req_auto_event_list.length > 0) {
				for(var idx in req_auto_event_list){
					// console.log(req_auto_event_list[idx]);
					// 파일명 규칙 설정 불러오기
					await this.getFileNameSettingList(req_auto_event_list[idx]);

					let org_event_banner_pc_width = req_auto_event_list[idx].org_event_banner_pc_width;
					let org_event_banner_pc_height = req_auto_event_list[idx].org_event_banner_pc_height;
					let org_event_banner_mobile_width = req_auto_event_list[idx].org_event_banner_mobile_width;
					let org_event_banner_mobile_height = req_auto_event_list[idx].org_event_banner_mobile_height;
					let event_banner_pc_width_height = req_auto_event_list[idx].event_banner_pc_width_height;
					let event_banner_mobile_width_height = req_auto_event_list[idx].event_banner_mobile_width_height;

					let pc_req_map = {};
					let mobile_req_map = {};
					let pc_mobile_same_ratio = false; // pc/mobile형 비율 사이즈가 정확하게 일치하면 true, 셀렉트박스에 하나의 설정만 들어가도록 한다. 
					
					if(org_event_banner_pc_width == org_event_banner_mobile_width && org_event_banner_pc_height == org_event_banner_mobile_height){
						pc_mobile_same_ratio = true;
					}

					var pc_org_obj = {
						"org_banner_width":   org_event_banner_pc_width,
						"org_banner_height": org_event_banner_pc_height,
						"org_event_type" : "p",
						"org_width_height" : event_banner_pc_width_height,
					};
					if (event_banner_pc_width_height && event_banner_pc_width_height > 1) {
						// 원본 pc 비율이 1보다 큰 경우
						pc_org_obj = this.getCaptureSize("pc",pc_org_obj);
						pc_req_map = {
							  event_seq : req_auto_event_list[idx].event_seq
							, platform_type : pc_org_obj.org_event_type
							, org_banner_width : pc_org_obj.org_banner_width
							, org_banner_height : pc_org_obj.org_banner_height
							, banner_width : pc_org_obj.banner_width
							, banner_height : pc_org_obj.banner_height
							, mirror_type : mirror_type
							, file_name : this.getMultiBannerDownloadFileName(req_auto_event_list[idx], "w")
						}
						//pc_banner_ratio_list.push(pc_org_obj);
					} else if (event_banner_pc_width_height && event_banner_pc_width_height <= 1){
						// 원본 pc 비율이 1보다 작거나 같은 경우
						pc_org_obj = this.getCaptureSize("mobile",pc_org_obj);
						pc_req_map = {
							  event_seq : req_auto_event_list[idx].event_seq
							, platform_type : "m"	//pc_org_obj.org_event_type
							, org_banner_width : pc_org_obj.org_banner_width
							, org_banner_height : pc_org_obj.org_banner_height
							, banner_width : pc_org_obj.banner_width
							, banner_height : pc_org_obj.banner_height
							, mirror_type : mirror_type
							, file_name : this.getMultiBannerDownloadFileName(req_auto_event_list[idx], "h")
						}
						//mobile_banner_ratio_list.push(pc_org_obj);
					}

					if(!pc_mobile_same_ratio){
						var mobile_org_obj = {
							"org_banner_width": org_event_banner_mobile_width,
							"org_banner_height": org_event_banner_mobile_height,
							"org_event_type" : "m",
							"org_width_height" : event_banner_mobile_width_height,
						};
						if (event_banner_mobile_width_height && event_banner_mobile_width_height <= 1){
							// 원본 모바일 비율이 1보다 작거나 같은 경우
							mobile_org_obj = this.getCaptureSize("mobile",mobile_org_obj);
							mobile_req_map = {
								  event_seq : req_auto_event_list[idx].event_seq
								, platform_type : mobile_org_obj.org_event_type
								, org_banner_width : mobile_org_obj.org_banner_width
								, org_banner_height : mobile_org_obj.org_banner_height
								, banner_width : mobile_org_obj.banner_width
								, banner_height : mobile_org_obj.banner_height
								, mirror_type : mirror_type
								, file_name : this.getMultiBannerDownloadFileName(req_auto_event_list[idx], "h")
							}
							//mobile_banner_ratio_list.push(mobile_org_obj);
							// 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
							// if(!mobile_req_map.org_banner_width){
							// 	mobile_req_map = mobile_req_map;
							// }
						} else if (event_banner_mobile_width_height && event_banner_mobile_width_height > 1) {
							// 원본 모바일 비율이 1보다 큰 경우
							mobile_org_obj = this.getCaptureSize("pc",mobile_org_obj);
							mobile_req_map = {
								  event_seq : req_auto_event_list[idx].event_seq
								, platform_type : "p"	//mobile_org_obj.org_event_type
								, org_banner_width : mobile_org_obj.org_banner_width
								, org_banner_height : mobile_org_obj.org_banner_height
								, banner_width : mobile_org_obj.banner_width
								, banner_height : mobile_org_obj.banner_height
								, mirror_type : mirror_type
								, file_name : this.getMultiBannerDownloadFileName(req_auto_event_list[idx], "w")
							}
							//pc_banner_ratio_list.push(mobile_org_obj);
							// 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
							// if(!pc_req_map.org_banner_width){
							// 	pc_req_map = mobile_req_map;
							// }
						}
					}
					req_list.push(pc_req_map);
					req_list.push(mobile_req_map);
				}
				// console.log(req_list);
				// 요청 목록 전송
				//this.contentDownload(file_name, is_download, req_list);
				// 다운로드 준비상태 : 진행중
				this.setState({[download_type]:false}, () => {
					new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
					.then( () => {
						CommonDownloadManager.fn_contentDownload(file_name, req_list, req_host, test_yn);
						// 다운로드 준비상태 : 완료
						this.setState({[download_type]:true});
					});
				});
			} else {
				alert("캡처할 배너를 선택해주세요.");
			}
		} else {
			alert("캡처 요청을 처리중 입니다.");
		}
	}

	// 이미지 다운로드
    contentDownload(file_name, download_type, req_list) {
        // 다운로드 준비상태 : 진행중
        this.setState({[download_type]:false});
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        http.post("/rest/shop/cafe24/event/capture_v2", { req_list : req_list }, config )
        .then(res => {
            if (res.data.code == "200") {
                // 성공시 s3 파일 다운로드 요청
                // this.fn_s3ImageFileDownload(res.data.capture_code, file_name);
                // 캡처요청 상태 조회
                let limit_count = 0;
                this.fn_captureStateChecker(res.data.req_seq, res.data.multi_image_yn, file_name, download_type, limit_count);
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            //console.log (error);
            alert("배너 다운로드 요청 실패\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        });
    }

    // 캡처요청 상태 조회
    fn_captureStateChecker(req_seq, multi_image_yn, file_name, download_type, limit_count) {
        limit_count = limit_count + 1
		const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
		http.post("/rest/shop/cafe24/content/stateChecker", { req_seq : req_seq, multi_image_yn : multi_image_yn }, config )
        .then(res => {
            if (res.data.is_done == "D") {
                //console.log("[0] " + limit_count +  "/ " + res.data.content_code);
                this.fn_s3FileDownload(res.data.content_code, file_name, download_type);
            } else if (res.data.is_done == "F") {
				// 요청 실패시 종료
				alert(res.data.msg+"\n고객센터 : 1599-3518");
			} else {
				// 미완료시 재요청
                if (limit_count < 200) {
                    new Promise( ( resolve, reject ) => {setTimeout( resolve, 3000 ); })
                    .then( () => {
                        //console.log("[1] : " + limit_count + " : 호출 재시도 ");
                        // 캡처요청 상태 조회 재시도
                        this.fn_captureStateChecker(req_seq, multi_image_yn, file_name, download_type, limit_count);
                    });
                } else {
                    alert("이미지 캡처 상태조회 실패\n고객센터 : 1599-3518");
                    // 다운로드 준비상태 : 완료
                    this.setState({[download_type]:true});
                }
            }
        })
        .catch(error => {
            alert("이미지 캡처 상태조회 오류\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
            console.log("#### fn_captureStateChecker [ERROR] start ####");
            console.log(error);
            console.log("#### fn_captureStateChecker [ERROR] end ####");
        });
    }

    // s3 파일 다운로드
    fn_s3FileDownload(content_code, file_name, download_type) {
        // 파일 확장자 추출
        let file_type = content_code.substring(content_code.lastIndexOf(".")+1, content_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/shop/cafe24/content/download",
            {
                "content_code" : content_code
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                if(file_name){
                    // 파일명으로 사용 불가능한 특수문자 제거
                    file_name = file_name.replace(/[`~!@#$%^&*|\\\'\";:\/?]/gi, "");
                } else{
                    // 상품명(제목) 미입력시 '제목없음'로 다운로드
                    file_name = '제목없음';
                }
                file_name = file_name + '.'+file_type;
                var blob = new Blob([res.data], { type: file_type });

				// 퍼센트 기호(%)가 포함되어 있으면서 올바른 퍼센트 인코딩이 아닌 경우 찾기
                const invalidPercentEncoding = /%(?![0-9A-Fa-f]{2})/;
                if (!invalidPercentEncoding.test(file_name)) {
                    file_name = decodeURI(file_name);
                }

                FileDownload(blob, file_name);
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            // 다운로드 준비상태 : 완료
            this.setState({[download_type]:true});
        });
    }

	// 배너 목록 다중 삭제 버튼 클릭
	deleteMultiAutoEventListOnClick() {
		const {event_list, checked_auto_event_list} = this.state;
		let delete_auto_event_list = JSON.parse(JSON.stringify(checked_auto_event_list));

		for(var idx in delete_auto_event_list){
			console.log(delete_auto_event_list[idx])
		}

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/list/delete';
		const body = {
			list : delete_auto_event_list
		};

		if (delete_auto_event_list != null && delete_auto_event_list.length > 0) {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "선택하신 배너를 삭제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											checked_auto_event_list: [],
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "삭제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															this.setState({ basic_dialog_show_state: false });
														}
													}
												],
											});
											this.getEventList();
										});

									} else if (res.data.code == "400") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false
										});

									} else {
										alert("삭제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false
										});
									}
								})
								.catch((error) => {
									alert("삭제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false
									});
									console.log(error);
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}else {
			alert("삭제할 배너를 선택해주세요.");
		}
	}

	// 배너 목록 개별 삭제 버튼 클릭
	deleteOneAutoEventListOnClick(e, event) {
		let event_seq = event.event_seq;
		console.log("event_seq: " + event_seq);

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/one/delete';
		const body = {
			event_seq : event_seq,
			event_type_cd : event.event_type_cd,
			event_mobile_type_cd : event.event_mobile_type_cd,
			color_code_1 : event.color_code_1,
			color_code_2 : event.color_code_2,
			color_code_3 : event.color_code_3,
			color_code_4 : event.color_code_4,
			title : event.title,
			foreign_title : event.foreign_title,
			display_yn : event.display_yn,
			product_no : event.product_no,
			hist_type : event.hist_type,
			reg_date : event.reg_date,
			iframe_page_id : event.iframe_page_id, 
			iframe_html_id : event.iframe_html_id
		};

		if (event_seq != null && event_seq != "") {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "이 배너를 삭제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											checked_auto_event_list: [],
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "삭제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															this.setState({ basic_dialog_show_state: false });
														}
													}
												],
											});
											this.getEventList();
										});

									} else if (res.data.code == "400") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false
										});

									} else {
										alert("삭제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false
										});
									}
								})
								.catch((error) => {
									alert("삭제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false
									});
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}
	}

	/** 셀렉트박스 선택된 값 idx
		 * @param {*} list 셀렉트 박스에서 사용하는 리스트
		 * @param {*} text_name 선택된 값에 대한 텍스트 필드명
		 * @param {*} value_name 셀렉트 박스 선택된 값 필드명
		 * @param {*} value 셀렉트 박스 선택된 값
		*/
	getSelectBoxSelectIdx(list, text_name, value_name, value){
		if(list != null && list != "" && list.length > 0){
			for(var i=0;i<list.length;i++){
				if(list[i][value_name] == value){
					return list[i][text_name];
				}
			}
		}
		return "";
	}

	/** 순서 변경 팝업 높이 조절 */
	onLoadOrderChangeTable(){
		let window_height = $(window).height();
		// window 높이에 따라서 메인테이블의 max-height가 달라지도록 적용(드래그 스크롤을 위한 작업)
		$("#order_change_popup").css({"max-height":window_height-160 > 550 ? window_height-160 : 550});
		$("#order_change_table_tbody").css({"max-height":window_height-660 > 100 ? window_height-660 : 100});
	}

	/**
	 * 배너 순서 변경 팝업 : iframe_page_id 변경
	 * @param {*} text 
	 * @param {*} value 
	 * @param {*} id 
	 */
	onChangeSelectOrderPageId(text, value, id){
		this.setState({
			order_iframe_page_id : value,
		}, () => {
			this.getOrderPopupHtmlId(this.state.order_iframe_page_id);
		});
	}  

	/** 배너 순서 변경 팝업 : html 목록 불러오기 */
	async getOrderPopupHtmlId(page_id) {
		const res = await http.get("/rest/common/robomd/PageHtmlId?shop_no="+"1"+"&type=html&iframe_page_id="+page_id);
		if(res.data != null) {
			this.setState({
				order_iframe_html_id_list: res.data.html_id_list,
				order_iframe_html_id : null,
			});
		}
	};

	/**
	 * 배너 순서 변경 팝업 : iframe_html_id 변경
	 * @param {*} text 
	 * @param {*} value 
	 * @param {*} id 
	 */
	onChangeSelectOrderHtmlId(text, value, id){
		this.setState({
			order_iframe_html_id : value,
		});
	}

	/** 배너 순서 변경 팝업 : 배너 목록 불러오기 */
	async getOrderPopupEventList() {
		if(!this.state.order_iframe_page_id || !this.state.order_iframe_html_id){
			return false;
		}

		this.setState({show_order_popup_loading_bar : true,})
		await http.get("/rest/shop/auto/event/order/list?iframe_page_id="+this.state.order_iframe_page_id+"&iframe_html_id="+this.state.order_iframe_html_id)
		.then(res => {
			if(res.data.err_code == 401) {
				window.location.href = "/logout";
			}
			if(res.data["list"] != null) {
				let order_event_idx = [];
				for(var i=0;i<res.data["list"].length;i++){
					order_event_idx.push(i+1);
				}

				this.setState({
					order_popup_event_list : res.data["list"],
					search_iframe_page_id : this.state.order_iframe_page_id,
					search_iframe_html_id : this.state.order_iframe_html_id,
					search_iframe_html_id_list : this.state.order_iframe_html_id_list,
					order_list_is_modified : false,
					show_order_popup_loading_bar : false,
				}, () => {
					this.onLoadOrderChangeTable();
					this.orderIdxReset();
				});
			}
		})
		.catch(error => {
			// console.log ("===== error =====");
			// console.log (error);
			// console.log ("===== error request =====");
			// console.log(error.request);
			this.setState({show_order_popup_loading_bar : false,})
		});
	}

	/** 배너 순서 변경 팝업 : 검색 버튼 클릭 */
	onClickSearchOrderList(){
		if(!this.state.order_iframe_page_id || !this.state.order_iframe_html_id){
			return false;
		}

		if(this.state.order_list_is_modified){
			this.setState({
				title_content_dialog_show_state : true,
				title_content_dialog_title : "저장 하시겠습니까?",
				title_content_dialog_content : "저장하지 않을 경우 변경한 값은<br/>따로 저장되지 않습니다.",
				title_content_dialog_button_list: [
					{
						title: "네",
						type: "1",
						event_method: () => {
							this.setState({
								title_content_dialog_show_state : false,
							}, () => {
								this.saveEventOrderList(true);
							});
						}
					},
					{
						title: "아니요",
						type: "0",
						event_method: () => {
							this.setState({
								title_content_dialog_show_state: false,
							}, () => {
								this.getOrderPopupEventList();
							});
						}
					}
				]
			})
			return false;
		}
		this.getOrderPopupEventList();
	}

	/** 상품의 순서를 나타내는 input값 타이핑 가능하도록 하는 함수
	 * @param {*} e < e.target.name : idx 번호, e.target.value : 입력 값>
	*/
	onChangeOrderInput(e) {
		const {order_event_idx} = this.state;
		order_event_idx[e.target.name] =  e.target.value; // 배열의 해당 위치값을 이벤트로 받아온 값으로 변경
		this.setState({
			order_event_idx:  order_event_idx, // 적용
		}, ()=>{
			// input width가 숫자 길이에 따라 변환되도록 조절
			$("#"+e.target.id).css({width: Number(((this.state.order_event_idx[e.target.name].length-1)*5)+24)+"px"});
		})
	}

	/** 상품의 순번을 순차적으로 초기화하는 함수 */
	orderIdxReset() {
		// 번호가 순차적으로 보일 수 있도록 order_event_idx를 초기화
		let order_event_idx = [];
		const {order_popup_event_list} = this.state;
		for(var i=0;i<order_popup_event_list.length;i++){
			order_event_idx[i]=i+1;
		}
		// 반영
		this.setState({
			order_event_idx: order_event_idx,
		}, ()=>{
			// input width가 숫자 길이에 따라 변환되도록 조절
			for(var i=0;i<this.state.order_event_idx.length;i++){
				let str_length = String(i+1).length;
				$("#order_change_input_"+i).css({width: Number(((str_length-1)*5)+24)+"px"});
			}
		})
	}

	/** 상품의 순서를 input 입력 값으로 변경하는 함수
	 * @param {*} e < e.target.name : idx 번호, e.target.value : 입력 값>
	 * @param {*} onBlur blur 된 경우
	*/
	onChangeOrderIdx(e,onBlur) {
		// 상품 목록
		const {order_popup_event_list} = this.state;
		// 엔터키를 눌렀을때 해당 순서 범위가 유효한 범위라면 순서 변경
		if(e.key=='Enter' || onBlur){
			if(e.target.value>0 && e.target.value<=order_popup_event_list.length){
				// 이전과 값이 달라진 경우
				if(e.target.name != e.target.value-1){
					const change_idx = Number(e.target.value)-1;
					const change_product = order_popup_event_list[e.target.name]; // 순서를 변경할 상품의 임시 저장소
					order_popup_event_list.splice(e.target.name,1); // 위치를 변경할 상품을 리스트에서 제거 후
					order_popup_event_list.splice(e.target.value-1,0,change_product); // 변경된 위치에 추가
					this.orderIdxReset(); // 순번 초기화
					
					this.setState({order_popup_event_list: order_popup_event_list}, () => {
						$("#order_change_input_"+change_idx).focus();
					});
					this.setState({order_list_is_modified: true,});
				}
			} else{
				this.orderIdxReset(); // 순번 초기화
			}
		}
	}

	/** 배너 순서 목록 저장 */
	saveEventOrderList(search, close){
		const config = {
			headers: {
				'Content-Type': 'application/json',
				},
		};
		const url = '/rest/shop/auto/event/order/save';
		const body = {
			list : this.state.order_popup_event_list,
			iframe_page_id : this.state.search_iframe_page_id,
			iframe_html_id : this.state.search_iframe_html_id,
		}

		http
		.post(url, body, config)
		.then((res) => {
			if (res.data.code == "200") {
				alert('저장되었습니다.');

				this.setState({order_list_is_modified : false,});
				if(search){
					this.getOrderPopupEventList();
				} else if(close){
					this.closeOrderChangePopup();
				}
			} else {
				alert('저장에 실패하였습니다.');
			}
		})
		.catch((error) => {
			alert('저장에 실패하였습니다.');
		});
	}

	/** 배너 순서 변경 팝업 : 닫기 버튼 클릭 */
	onClickCloseOrderChangePopup(){
		if(this.state.order_list_is_modified){
			this.setState({
				title_content_dialog_show_state : true,
				title_content_dialog_title : "저장 하시겠습니까?",
				title_content_dialog_content : "저장하지 않을 경우 변경한 값은<br/>따로 저장되지 않습니다.",
				title_content_dialog_button_list: [
					{
						title: "네",
						type: "1",
						event_method: () => {
							this.setState({
								title_content_dialog_show_state : false,
							}, () => {
								this.saveEventOrderList(false,true);
							});
						}
					},
					{
						title: "아니요",
						type: "0",
						event_method: () => {
							this.setState({
								title_content_dialog_show_state: false,
							}, () => {
								this.closeOrderChangePopup();
							});
						}
					}
				]
			});
			return false;
		}
		this.closeOrderChangePopup();
	}

	/** 배너 순서 변경 팝업 닫기 */
	closeOrderChangePopup(){
		this.setState({
			show_order_popup : false,
			order_iframe_page_id : null,
			order_iframe_html_id_list : [],
			order_iframe_html_id : null,
			order_popup_event_list : [],
			order_event_idx : [],
			search_iframe_page_id : null,
			search_iframe_html_id : null,
			search_iframe_html_id_list : [],
			order_list_is_modified : false,
		});
		StyleUtil.clearPopupNoneScroll(window_scrollTop);
	}

	// 배너 복제 버튼 클릭
	cloneAutoEventListOnClick(e, event) {
		let event_seq = event.event_seq;
		console.log("event_seq: " + event_seq);

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/event/list/clone';
		const body = {
			event_seq : event_seq,
			event_type_cd : event.event_type_cd,
			event_mobile_type_cd : event.event_mobile_type_cd,
			color_code_1 : event.color_code_1,
			color_code_2 : event.color_code_2,
			color_code_3 : event.color_code_3,
			color_code_4 : event.color_code_4,
			title : event.title,
			foreign_title : event.foreign_title,
			display_yn : event.display_yn,
			product_no : event.product_no,
			hist_type : event.hist_type,
			reg_date : event.reg_date
		};

		if (event_seq != null && event_seq != "") {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "이 배너를 복제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											checked_auto_event_list: [],
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "복제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															this.setState({ basic_dialog_show_state: false });
														}
													}
												],
											});
											this.handlePageChange(1);
										});

									} else if (res.data.code == "400" || res.data.code == "500") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false
										});

									} else {
										alert("복제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false
										});
									}
								})
								.catch((error) => {
									alert("복제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false
									});
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}
	}

	// 자동 배너 설정 조회
	async getAutoEventSetting(iframe_page_id, iframe_html_id) {
		try{
			const res = await http.get("/rest/shop/event/setting?iframe_page_id="+iframe_page_id+"&iframe_html_id="+iframe_html_id);

			if(res.data != null) {
				if (res.data.err_code == 401) {
					alert(res.data.err_msg);
					window.location.href = "/logout";
				}

				if(res.data.list[0] != null) {
					this.setState({
						auto_event_setting: res.data.list[0],
						file_name_setting_yn: res.data.list[0].file_name_setting_yn,
					});
				}
				return res.data;
			}
		}catch(error) {
			console.log(error);
		}
	}

	// 파일명 규칙 설정 조회
	async getFileNameSettingList(event) {
		try{
			const res = await http.get("/rest/shop/event/setting/file-name/setting?iframe_page_id="+event.iframe_page_id+"&iframe_html_id="+event.iframe_html_id);

			if(res.data != null) {
				if (res.data.code == 401) {
					alert(res.data.msg);
					window.location.href = "/logout";
				}

				if(res.data.file_name_setting_list != null && res.data.file_name_setting_list.length > 0) {
					this.setState({
						file_name_setting_list : res.data.file_name_setting_list,
						w_file_name_setting_list : res.data.w_file_name_setting_list,
						h_file_name_setting_list : res.data.h_file_name_setting_list,
					});

				}else {
					this.setState({
						file_name_setting_list : [],
						w_file_name_setting_list : [],
						h_file_name_setting_list : [],
					});

					// 파일명 규칙 기본 설정 불러오기
					await this.getFileNameDefaultSetting(event);
				}

				return res.data;
			}

		}catch(error) {
			console.log(error);
		}
	}

	// 파일명 규칙 기본 설정 조회
	async getFileNameDefaultSetting() {
		try{
			const res = await http.get("/rest/shop/event/setting/file-name/default");

			if(res.data != null) {
				if (res.data.code == 401) {
					alert(res.data.msg);
					window.location.href = "/logout";
				}

				if(res.data.file_name_default_setting_list != null && res.data.file_name_default_setting_list.length > 0) {
					this.setState({
						file_name_default_setting_list: res.data.file_name_default_setting_list,
						w_file_name_default_setting_list: res.data.w_file_name_default_setting_list,
						h_file_name_default_setting_list: res.data.h_file_name_default_setting_list,
					});
				}
			}

		}catch(error) {
			console.log(error);
		}
	}

	getDateFormat = (date) => {
		let dateFormat = new Date(date);
		let year = dateFormat.getFullYear();
		let month = ('0' + (dateFormat.getMonth() + 1)).slice(-2);
		let day = ('0' + dateFormat.getDate()).slice(-2);
		let dateString = year + month + day;

		return dateString;
	}

	getDayOfWeek = (date) => {
		const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
		const dayOfWeek = week[new Date(date).getDay()];

		return dayOfWeek;
	}

	getAutoNumber = (start_num, increment_value) => {
		const padded_increment_value = increment_value.toString().padStart(String(start_num).length, '0');
		const incremented_num = parseInt(start_num) + parseInt(padded_increment_value);
		const auto_num = incremented_num.toString().padStart(String(start_num).length, '0');

		return auto_num;
	}

	// 파일명 규칙 자동 번호 저장
	async updateFileNameAutoNum(event, type, rules_number, rules_auto_num) {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		let body = {
			iframe_page_id: event.iframe_page_id,
			iframe_html_id: event.iframe_html_id,
			banner_type: type,
			rules_number: rules_number,
			rules_auto_num: rules_auto_num
		}

		try{
			const res = await http.post("/rest/shop/event/setting/file-name/auto-num", body, config);

		}catch(error) {
			console.log(error);
		}
	}

	getFileName = (event, type, rules_number, code_cd, second_code_cd, third_code_cd, rules_date, rules_name, rules_auto_num) => {
		let file_name = "";

		switch (code_cd) {
			case "61000":   // 페이지 위치
				file_name = event.iframe_page_id.split("_")[1];
				break;

			case "62000":   // 배너 영역명
				file_name = event.iframe_html_name;
				break;

			case "63000":   // 배너 사이즈
				if(second_code_cd == "63001") {  // 픽셀(가로x세로)
					if(type == "w") {
						file_name = "픽셀(" + String(event.org_event_banner_pc_width) + "x" + String(event.org_event_banner_pc_height) + ")";
					}else if(type == "h") {
						file_name = "픽셀(" + String(event.org_event_banner_mobile_width) + "x" + String(event.org_event_banner_mobile_height) + ")";
					}
				}else if(second_code_cd == "63002") {   // 비율(가로x세로)
					if(type == "w") {
						file_name = "비율(" + String(Number(event.event_banner_pc_width_height).toFixed(1)) + "x1)";
					}else if(type == "h") {
						file_name = "비율(" + String(Number(event.event_banner_mobile_width_height).toFixed(2)) + "x1)";
					}
				}else if(second_code_cd == "63003" || second_code_cd == "63004" || second_code_cd == "63005" || second_code_cd == "63006" || second_code_cd == "63007" || second_code_cd == "63008" || second_code_cd == "63009" || second_code_cd == "63010") {    // PC, MO, pc, m, mobile, 가로, 세로, 직접 입력
					file_name = rules_name;
				}
				break;

			case "64000":   // 날짜
				if(second_code_cd == "64001") {     // 배너 등록일
					if(third_code_cd == "65001") {          // YYYYMMDD
						file_name = this.getDateFormat(event.reg_date);
					}else if(third_code_cd == "65002") {    // YYMMDD
						file_name = this.getDateFormat(event.reg_date).substring(2);
					}else if(third_code_cd == "65003") {    // MMDD
						file_name = this.getDateFormat(event.reg_date).substring(4);
					}else if(third_code_cd == "65004") {    // 요일
						file_name = this.getDayOfWeek(event.reg_date);
					}

				}else if(second_code_cd == "64002") {   // 최종 수정일
					if(third_code_cd == "65001") {          // YYYYMMDD
						file_name = this.getDateFormat(event.mod_date);
					}else if(third_code_cd == "65002") {    // YYMMDD
						file_name = this.getDateFormat(event.mod_date).substring(2);
					}else if(third_code_cd == "65003") {    // MMDD
						file_name = this.getDateFormat(event.mod_date).substring(4);
					}else if(third_code_cd == "65004") {    // 요일
						file_name = this.getDayOfWeek(event.mod_date);
					}

				}else if(second_code_cd == "64003") {   // 다운로드 날짜
					if(third_code_cd == "65001") {          // YYYYMMDD
						file_name = this.getDateFormat(new Date());
					}else if(third_code_cd == "65002") {    // YYMMDD
						file_name = this.getDateFormat(new Date()).substring(2);
					}else if(third_code_cd == "65003") {    // MMDD
						file_name = this.getDateFormat(new Date()).substring(4);
					}else if(third_code_cd == "65004") {    // 요일
						file_name = this.getDayOfWeek(new Date());
					}

				}else if(second_code_cd == "64004") {   // 날짜 직접 선택
					if(third_code_cd == "65001") {          // YYYYMMDD
						file_name = this.getDateFormat(rules_date);
					}else if(third_code_cd == "65002") {    // YYMMDD
						file_name = this.getDateFormat(rules_date).substring(2);
					}else if(third_code_cd == "65003") {    // MMDD
						file_name = this.getDateFormat(rules_date).substring(4);
					}else if(third_code_cd == "65004") {    // 요일
						file_name = this.getDayOfWeek(rules_date);
					}
				}
				break;

			case "66000":   // 자동 번호
				file_name = rules_auto_num;

				const updated_num = this.getAutoNumber(rules_auto_num, 1);
				this.updateFileNameAutoNum(event, type, rules_number, updated_num).then(r => {});

				break;

			case "67000":	// 배너 이름
				file_name = event.title;
				break;

			case "69999":   // 직접 입력
				file_name = rules_name;
				break;

			default:
				break;

		}
		return file_name;
	}

	getBannerDownloadFileName = (event, type) => {
		let _file_name = "";

		// 파일명 규칙 설정 여부
		if (this.state.file_name_setting_yn == "Y") {     // 파일명 규칙 설정이 "Y"인 경우 (파일명 규칙 설정이 "직접 설정"인 경우)

			if (this.state.file_name_setting_list != null && this.state.file_name_setting_list.length > 0) {    // 파일명 규칙 설정 목록이 존재하는 경우

				// 저장된 파일명 규칙 설정에 맞게 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
				this.state[type + "_file_name_setting_list"].map((obj, idx) => {
					_file_name += String(this.getFileName(event, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

					if (idx + 1 < this.state[type + "_file_name_setting_list"].length) {
						_file_name += "_";
					}
				});

			} else {    // 파일명 규칙 설정 목록이 존재하지 않는 경우

				// 파일명 규칙 기본 설정으로 다운로드. ex) 규칙1_규칙2_규칙3_규칙4.jpg
				if(this.state[type + "_file_name_default_setting_list"] != null && this.state[type + "_file_name_default_setting_list"].length>0){
					this.state[type + "_file_name_default_setting_list"].map((obj, idx) => {
						_file_name += String(this.getFileName(event, obj.banner_type, obj.rules_number, obj.code_cd, obj.second_code_cd, obj.third_code_cd, obj.rules_date, obj.rules_name, obj.rules_auto_num));

						if(idx+1 < this.state[type + "_file_name_default_setting_list"].length) {
							_file_name += "_";
						}
					});
				}
			}

		} else if(this.state.file_name_setting_yn == "D") {     // 파일명 규칙 설정이 "D"인 경우

			// 로보MD_배너종류_배너번호로 다운로드
			if(event.reg_user_seq == -1) {
				_file_name = "로보MD_자동배너_" + event.event_seq;

			}else if(event.mod_user_seq == -2) {
				_file_name = "로보MD_샘플배너_" + event.event_seq;

			}else if(event.event_promotion_type_cd != null && event.event_promotion_type_cd == "19002"){
				_file_name = "로보MD_마이배너_" + event.event_seq;

			}else {
				_file_name = "로보MD_로보배너_" + event.event_seq;
			}

		}else if(this.state.file_name_setting_yn == "N") {      // 파일명 규칙 설정이 "N"인 경우 (파일명 규칙 설정이 "기본 설정"인 경우)
			_file_name = event.title;
		}

		return _file_name;
	}

	// 배너 목록 개별 다운로드 버튼 클릭
	downloadOneEventListBtnClick = (e, event) => {
		this.setState({download_event_seq: e.target.parentElement.className.split(" ")[1].substr(24, 5)});

		// 자동 배너 설정 불러오기
		this.getAutoEventSetting(event.iframe_page_id, event.iframe_html_id).then(data => {
			if(data.list[0] != null) {
				this.setState({
					auto_event_setting: data.list[0],
					file_name_setting_yn: data.list[0].file_name_setting_yn,
				}, () => {

					// 파일명 규칙 설정 불러오기
					this.getFileNameSettingList(event).then(data => {
						this.setState({
							file_name_setting_list : data.file_name_setting_list,
							w_file_name_setting_list : data.w_file_name_setting_list,
							h_file_name_setting_list :data.h_file_name_setting_list,
						}, () => {

							// 배너 다운로드
							this.downloadBannerHandler(event);
						});
					});
				});

			}else{
				// 파일명 규칙 설정 불러오기
				this.getFileNameSettingList(event).then(data => {
					this.setState({
						file_name_setting_list : data.file_name_setting_list,
						w_file_name_setting_list : data.w_file_name_setting_list,
						h_file_name_setting_list :data.h_file_name_setting_list,
					}, () => {

						// 배너 다운로드
						this.downloadBannerHandler(event);
					});
				});
			}
		});
	}

	// 배너 다운로드
	downloadBannerHandler = (event) => {
		let w_file_name = "";
		let h_file_name = "";
		let mirror_type = "18002";      // 18001(미리보기), 18002
		let w_req_list = [];
		let h_req_list = [];

		let org_event_banner_pc_width = event.org_event_banner_pc_width;
		let org_event_banner_pc_height = event.org_event_banner_pc_height;
		let org_event_banner_mobile_width = event.org_event_banner_mobile_width;
		let org_event_banner_mobile_height = event.org_event_banner_mobile_height;
		let event_banner_pc_width_height = event.event_banner_pc_width_height;
		let event_banner_mobile_width_height = event.event_banner_mobile_width_height;

		let pc_req_map = {};
		let mobile_req_map = {};
		let pc_mobile_same_ratio = false; // pc/mobile형 비율 사이즈가 정확하게 일치하면 true, 셀렉트박스에 하나의 설정만 들어가도록 한다.

		if(org_event_banner_pc_width == org_event_banner_mobile_width && org_event_banner_pc_height == org_event_banner_mobile_height){
			pc_mobile_same_ratio = true;
		}

		var pc_org_obj = {
			"org_banner_width":   org_event_banner_pc_width,
			"org_banner_height": org_event_banner_pc_height,
			"org_event_type" : "p",
			"org_width_height" : event_banner_pc_width_height,
		};
		if (event_banner_pc_width_height && event_banner_pc_width_height > 1) {
			// 원본 pc 비율이 1보다 큰 경우
			pc_org_obj = this.getCaptureSize("pc",pc_org_obj);
			pc_req_map = {
				event_seq : event.event_seq
				, platform_type : pc_org_obj.org_event_type
				, org_banner_width : pc_org_obj.org_banner_width
				, org_banner_height : pc_org_obj.org_banner_height
				, banner_width : pc_org_obj.banner_width
				, banner_height : pc_org_obj.banner_height
				, mirror_type : mirror_type
				, file_name : this.getBannerDownloadFileName(event, "w")
			}
			// pc_banner_ratio_list.push(pc_org_obj);
		} else if (event_banner_pc_width_height && event_banner_pc_width_height <= 1){
			// 원본 pc 비율이 1보다 작거나 같은 경우
			pc_org_obj = this.getCaptureSize("mobile",pc_org_obj);
			pc_req_map = {
				event_seq : event.event_seq
				, platform_type : "m"	//pc_org_obj.org_event_type
				, org_banner_width : pc_org_obj.org_banner_width
				, org_banner_height : pc_org_obj.org_banner_height
				, banner_width : pc_org_obj.banner_width
				, banner_height : pc_org_obj.banner_height
				, mirror_type : mirror_type
				, file_name : this.getBannerDownloadFileName(event, "h")
			}
			// mobile_banner_ratio_list.push(pc_org_obj);
		}

		if(!pc_mobile_same_ratio) {
			var mobile_org_obj = {
				"org_banner_width": org_event_banner_mobile_width,
				"org_banner_height": org_event_banner_mobile_height,
				"org_event_type": "m",
				"org_width_height": event_banner_mobile_width_height,
			};
			if (event_banner_mobile_width_height && event_banner_mobile_width_height <= 1) {
				// 원본 모바일 비율이 1보다 작거나 같은 경우
				mobile_org_obj = this.getCaptureSize("mobile", mobile_org_obj);
				mobile_req_map = {
					event_seq: event.event_seq
					, platform_type: mobile_org_obj.org_event_type
					, org_banner_width: mobile_org_obj.org_banner_width
					, org_banner_height: mobile_org_obj.org_banner_height
					, banner_width: mobile_org_obj.banner_width
					, banner_height: mobile_org_obj.banner_height
					, mirror_type: mirror_type
					, file_name: this.getBannerDownloadFileName(event, "w")
				}
				// mobile_banner_ratio_list.push(mobile_org_obj);
				// 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
				// if(!mobile_req_map.org_banner_width){
				// 	mobile_req_map = mobile_req_map;
				// }
			} else if (event_banner_mobile_width_height && event_banner_mobile_width_height > 1) {
				// 원본 모바일 비율이 1보다 큰 경우
				mobile_org_obj = this.getCaptureSize("pc", mobile_org_obj);
				mobile_req_map = {
					event_seq: event.event_seq
					, platform_type: "p"	//mobile_org_obj.org_event_type
					, org_banner_width: mobile_org_obj.org_banner_width
					, org_banner_height: mobile_org_obj.org_banner_height
					, banner_width: mobile_org_obj.banner_width
					, banner_height: mobile_org_obj.banner_height
					, mirror_type: mirror_type
					, file_name: this.getBannerDownloadFileName(event, "h")

				}
				// pc_banner_ratio_list.push(mobile_org_obj);
				// 비연동형에서 입력값이 모바일이였다면, 해당 비율을 선택값으로 설정
				// if(!pc_req_map.org_banner_width){
				// 	pc_req_map = mobile_req_map;
				// }
			}
		}
		w_req_list.push(pc_req_map);
		h_req_list.push(mobile_req_map);

		w_file_name = this.getBannerDownloadFileName(event, "w");
		h_file_name = this.getBannerDownloadFileName(event, "h");

		// 파일명 규칙 설정이 "기본 설정"일 경우 파일명 카운트 저장하기
		if(event.file_name_setting_yn == "N"){
			if(w_file_name != null && w_file_name != ""){
				this.saveFileNameCnt("w", w_file_name, event).then(res => {
					w_file_name = res;
				});
			}

			if(h_file_name != null && h_file_name != ""){
				this.saveFileNameCnt("h", h_file_name, event).then(res => {
					h_file_name = res;
				});
			}
		}

		if(w_file_name == null || w_file_name == ""){
			w_file_name = event.title;
		}

		if(h_file_name == null || h_file_name == "") {
			h_file_name = event.title;
		}

		// 요청 목록 전송
		//this.contentDownload(file_name, is_download, req_list);
		// 다운로드 준비상태 : 진행중

		if(w_req_list[0].event_seq != null && w_req_list[0].event_seq != "") {
			this.setState({is_banner_download:false}, () => {
				new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
					.then( () => {
						CommonDownloadManager.fn_contentDownload(w_file_name, w_req_list);
						// 다운로드 준비상태 : 완료
						this.setState({is_banner_download:true});
					});
			});
		}

		if(h_req_list[0].event_seq != null && h_req_list[0].event_seq != "") {
			this.setState({is_banner_download:false}, () => {
				new Promise( ( resolve, reject ) => {setTimeout( resolve, 500 ); })
					.then( () => {
						CommonDownloadManager.fn_contentDownload(h_file_name, h_req_list);
						// 다운로드 준비상태 : 완료
						this.setState({is_banner_download:true});
					});
			});
		}
	}

	// 파일명 카운트 저장하기
	async saveFileNameCnt(type, file_name, event) {
		let _file_name = "";
		let _file_name_num = "";

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		let body = {
			iframe_page_id: event.iframe_page_id,
			iframe_html_id: event.iframe_html_id,
			banner_type: type,
			file_name: file_name
		}

		try{
			const res = await http.post("/rest/shop/event/setting/file-name/cnt", body, config);

			if(res.data != null) {
				if(res.data.code == "200"){
					if(res.data.file_name_cnt > 0){
						_file_name_num = res.data.file_name_cnt;
						_file_name = file_name + "_" + this.padWithZero(Number(_file_name_num));
					}else {
						_file_name = file_name;
					}

					return _file_name;
				}
			}
		}catch(error) {
			console.log(error);
		}
	}

	padWithZero(num) {
		if (num < 10) {
			return "0" + num;
		} else {
			return num.toString();
		}
	}

	  render() {
		// alert(this.state.orderGubun);
		// alert(this.state.sorting);
		//$(".pageName").text("자동기획전 관리");
		$(".pageName").text("배너 목록");
		return (
			<>
				<div id="common-page-wrapper" className="container eventList" style={{backgroundColor: '#ffffff'}}>
					<div id="event_list_component" class="event_list_page_area" style={{height:"92vh"}}>
						{/* <h3>[Shop] 기획전 목록</h3> */}
						{this.state.solution_type_cd == "09900"
						?<div className="filter">
							<div style={{position:"relative"}}>
								<div className="input-group m-b mobile_width_100 event_list_cal wrap_pc_page_title">
									<div className="pc_page_title" style={{fontSize:"27px", margin: "auto 0 auto 0"}}>
										<b>배너 목록</b>
									</div>
								</div>
								<div className={"table_function_area"}>
									<div className={"table_fuction_left_area"}>
										<NavLink
											to={{
												pathname: `/shop/cafe24/event/form`,
												state: {prevPath: window.location.href}
											}}>
											<Button width={120} height={36} fontSize={13}>
												<div className={"event_add_btn"}>
													<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 8C14 8.13261 13.9473 8.25979 13.8536 8.35355C13.7598 8.44732 13.6326 8.5 13.5 8.5H8.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V8.5H2.5C2.36739 8.5 2.24021 8.44732 2.14645 8.35355C2.05268 8.25979 2 8.13261 2 8C2 7.86739 2.05268 7.74021 2.14645 7.64645C2.24021 7.55268 2.36739 7.5 2.5 7.5H7.5V2.5C7.5 2.36739 7.55268 2.24021 7.64645 2.14645C7.74021 2.05268 7.86739 2 8 2C8.13261 2 8.25979 2.05268 8.35355 2.14645C8.44732 2.24021 8.5 2.36739 8.5 2.5V7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8Z" fill="#333333"/></svg>
													배너 추가하기
												</div>
											</Button>
										</NavLink>
									</div>
									<div className={"table_fuction_right_area"}>
										<div className={"select_list"}>
											<Select
												className={"react-select"}
												defaultValue={this.state.iframe_html_id_list.find(obj => obj.iframe_html_id == this.state.iframe_html_id)}
												value={this.state.iframe_html_id_list.find(obj => obj.iframe_html_id == this.state.iframe_html_id)}
												components={animatedComponents}
												placeholder={"옵션을 선택해 주세요"}
												getOptionValue={(option) => option.iframe_html_id}
												getOptionLabel={(option) => option.iframe_html_name}
												noOptionsMessage={() => "없음"}
												options={this.state.iframe_html_id_list}
												onChange={option => this.handleChangeHtmlId(option.iframe_html_id)}
												styles={reactSelectStyles({height:"36px"})}
											/>
										</div>
										<div className={"search_area"}>
											<div className={"search_bar"}>
												<input value={this.state.title} onChange={(e) => {this.setState({title:e.target.value})}} onKeyDown={(e) => {e.key === "Enter" && this.handleSearchList()}} type='text' placeholder='배너 명칭으로 검색해 보세요.'/>
												<button>
													<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.9453 17.0547L14.5625 13.6797C15.7564 12.2583 16.3555 10.4308 16.2348 8.57844C16.114 6.72607 15.2828 4.99182 13.9146 3.73738C12.5463 2.48294 10.7465 1.80518 8.89063 1.84545C7.03477 1.88571 5.2661 2.6409 3.9535 3.9535C2.6409 5.2661 1.88571 7.03477 1.84545 8.89063C1.80518 10.7465 2.48294 12.5463 3.73738 13.9146C4.99182 15.2828 6.72607 16.114 8.57844 16.2348C10.4308 16.3555 12.2583 15.7564 13.6797 14.5625L17.0547 17.9453C17.1741 18.0608 17.3338 18.1254 17.5 18.1254C17.6662 18.1254 17.8258 18.0608 17.9453 17.9453C18.0627 17.8268 18.1285 17.6668 18.1285 17.5C18.1285 17.3332 18.0627 17.1732 17.9453 17.0547ZM3.125 9.0625C3.125 7.88817 3.47323 6.74022 4.12565 5.7638C4.77807 4.78738 5.70538 4.02636 6.79032 3.57696C7.87525 3.12757 9.06909 3.00999 10.2208 3.23909C11.3726 3.46819 12.4306 4.03368 13.2609 4.86405C14.0913 5.69443 14.6568 6.75239 14.8859 7.90415C15.115 9.05591 14.9974 10.2497 14.548 11.3347C14.0986 12.4196 13.3376 13.3469 12.3612 13.9993C11.3848 14.6518 10.2368 15 9.0625 15C7.48841 14.9979 5.97938 14.3717 4.86634 13.2587C3.75329 12.1456 3.12707 10.6366 3.125 9.0625Z" fill="#cccccc"/></svg>
												</button>
											</div>
											<Button type={"hover_grey"} width={"56px"} height={"36px"} fontSize={"13px"} onClick={() => this.handleSearchList()}>검색</Button>
										</div>
										<div className={"search_bar_btn_area"}>
											<Button type={"hover_white"} width={"80px"} height={"36px"} fontSize={"13px"} onClick={() => this.deleteMultiAutoEventListOnClick()}>
												선택 삭제
											</Button>
											<Button type={"hover_white"} width={"100px"} height={"36px"} fontSize={"13px"} onClick={() => this.fn_multiCapture()}>
												{
													this.state.is_download == true
													? "선택 다운로드"
													: 
													<div className="list_loading list_loading_download" style={{alignSelf: "center"}}>
														<span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
													</div>
												}
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
						:<div className="filter">
							<div style={{position:"relative"}}>
								<div className="input-group m-b mobile_width_100 event_list_cal wrap_pc_page_title">
									<div className="pc_page_title" style={{fontSize:"27px", margin: "auto 0 auto 0"}}>
										<b>배너 목록</b>
									</div>
								</div>
								<div className={"flexBox"} 
										id={"filter_head"}
										style={{position:"relative"}}>
									<div className={"flexBox"} style={{width: "100%", justifyContent: "space-between"}}>
										<div className="flexBox mobile-width-100">
											{/* <div className="input-group m-b mobile_flex_1" style={{width:"30%", flex:"1"}}>
												<label className="mobile_font_size_11">PC 배너 유형</label>
												<select id="event_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
												value={this.state.event_type_cd} onChange={(e) => this.handleChangeType(e)}>
													<option value="">
														전체
													</option>
													{this.state.event_type.map((qna, idx) => (	
														<option value={qna.code_cd} key={idx}>
															{qna.event_type_name}
														</option>								
													))};
												</select> 
											</div> */}
											<div className="input-group m-b mobile_flex_1" style={{width:"25%", flex:"1"}}>
												{/* <label className="mobile_font_size_11">mobile 배너 유형</label>
												<select id="event_mobile_type" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
												value={this.state.event_mobile_type_cd} onChange={(e) => this.handleChangeMobileType(e)}>
													<option value="">
														전체
													</option>
													{this.state.event_mobile_type.map((qna, idx) => (	
														<option value={qna.code_cd} key={idx}>
															{qna.event_mobile_type_name}
														</option>								
													))};
												</select> */}
												<div style={{margin: "-5.5px 0 5.5px 0", width: "100%"}}>
													<label className="input_text_title_d mobile_font_size_11">배너 페이지 종류</label>
													<select id="iframe_html_id" className="border_form_radius_b" style={{width:"100%"}}
													value={this.state.iframe_page_id} onChange={(e) => this.handleChangePageId(e)}>
														<option value="">
															전체
														</option>
														{this.state.iframe_page_id_list.map((obj, idx) => (	
															<option value={obj.code_name} key={idx}>
																{obj.val1}
															</option>								
														))};
													</select>
												</div>
											</div>
											<div className= {"input-group m-b mobile_flex_1"}
													style={{width:"25%", flex:"1", marginLeft:"10px"}}>
												<div style={{margin: "-5.5px 0 5.5px 0", width: "100%"}}>
													<label className="input_text_title_d mobile_font_size_11">배너 영역 종류</label>
													{
														this.state.iframe_html_id_list.length > 0
														? 	<select id="iframe_html_id" className="border_form_radius_b" style={{width:"100%"}}
															value={this.state.iframe_html_id} onChange={(e) => this.handleChangeHtmlId(e.target.value)}>
																{this.state.iframe_html_id_list.map((obj, idx) => (	
																	<option value={obj.iframe_html_id} key={idx}>
																		{obj.iframe_html_name}
																	</option>								
																))};
															</select>
														: 	<select id="iframe_html_id" className="border_form_radius_b" style={{width:"100%"}}
															value="">
																<option value="">
																	전체
																</option>
																<option value="" disabled>
																	배너 페이지 종류를 먼저 선택해 주세요.
																</option>
															</select>
													}
												</div>
											</div>
										</div>
										<div class={"list_btn_del_change_auto_event_area"} style={{float: "right"}}>
											<button className="list_btn_change_auto_event" style={{marginLeft:"10px"}} onClick={
												() => {
													this.setState({
														show_order_popup : true,
													}, () => {
														this.onLoadOrderChangeTable();
													})
													window_scrollTop = StyleUtil.onPopupNoneScroll(window_scrollTop);
												}
											}>배너 순서 변경</button>
											<button className={"list_btn_del_auto_event"} onClick={() => this.deleteMultiAutoEventListOnClick()}>
												선택 삭제
											</button>
											<button className={"list_btn_download_auto_event"} onClick={() => this.fn_multiCapture()}>
												{
													this.state.is_download == true
													? "선택 다운로드"
													: 
													<div className="list_loading list_loading_download" style={{alignSelf: "center"}}>
														<span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
													</div>
												}
											</button>
										</div>	
										<div className="cursor-pointer" id="btn_mobile_filter" 
											style={{position:"absolute", right:0, top: "15%", display:"none", fontWeight: "bold"}}
											onClick={e => this.onClickBtnMobileFilterOpen(e)}>
												<div style={{
													display: "flex",
													justifyContent: "center",
													flexDirection: "column"
												}}>
													<img src={filter_icon_143777} style={{width:"18px", margin:"auto"}}></img>
													<div>
														필터
													</div>	
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>}

						<div id="banner_list_body_area" style={{display:"flex", height:"calc(100vh - 310px)", width:"100%"}}>
							{/* 검색 필터 */}
							{
								this.state.solution_type_cd != "09900" 
								? 
									<div className="list_filter_area" style={{width:"285px", overflowY:"scroll"}}>
										<div style={{display:"flex", flexDirection: "column"}}>
											{/* <div className="border_form_radius_d" style={{padding: "20px"}}> */}
												{/* <div style={{fontSize:"15px"}}>
													<b>새로운 배너 만들기</b>																		
												</div> */}
												<div style={{display:"flex"}}>
													<NavLink 
														style={{color:"white", width: "280px", height:"63px", marginRight:"5px"}}
														to={{
															pathname: `/shop/cafe24/event/form`,
															state: {prevPath: window.location.href}
														}}>
														<div className="btn-175-b cursor-pointer" style={{width: "100%", height:"100%", textAlign:"center", paddingTop: "13px", fontSize:"13px"}}>
															{/* 로보MD<br/> */}
															상품 선택으로<br/>만들기
														</div>	
													</NavLink>
													<NavLink 
														style={{color:"white", width: "280px", height:"63px", marginLeft:"5px"}}
														to={{
															pathname: `/shop/cafe24/promotion/form`,
															state: {prevPath: window.location.href}
														}}>
														<div className="btn-175-b cursor-pointer" style={{width: "100%", height:"100%", textAlign:"center", paddingTop: "13px", fontSize:"13px"}}>
															{/* 프로모션<br/> */}
															내가 만든 이미지로<br/>만들기
														</div>
													</NavLink>
												</div>
											{/* </div> */}
											<div style={{position:"relative", width:"280px", display:"flex", marginTop:"20px"}}>
												<div class="btn_search" style={{position:"absolute", top:"35%", left:"20px"}}></div>
												<input className="border_form_radius_d" type="text" onChange={(e) => {
													// console.log(e.target.value)
													this.setState({ title: e.target.value })
												}} placeholder="배너를 검색해 보세요." style={{width:"100%", height: "35px",border:"1.5px solid #333333", padding:"30px 20px 30px 53px"}} onKeyDown={(e) => e.key == "Enter" && this.handleSearchList()}></input>
												{/* <button className = "greenButton" onClick={this.onClickSearchListButton} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button> */}
											</div>

											<div id="banner_list_date_area" className="border_form_radius_d" style={{flexDirection:"column", left:"0", top:"83px", width:"280px", height:"130px", display:"flex", padding:"0", marginTop:"20px"}}>
												<div className="m-t-20 m-l-20" style={{fontSize:"15px"}}>
													<b>목록기간 설정하기</b>																		
												</div>
												<div className="flexBox m-t-16 m-l-20" style={{position:"relative"}}>
													<div style={{fontSize:"13px"}}>
														시작																	
													</div>
													<div className="datePickerArea" style={{height:"18px"}}>
													<DatePicker name="start_date" id="start_date"
														selected={this.state.start_date}
														onSelect={this.handleSelect} //when day is clicked
														onChange={this.handleChangeStartDate} //only when value has changed
														// showTimeSelect
														dateFormat="yyyy-MM-dd"
														className={["border_none_input_calendar","list_datePicker"].join(' ')}
														autoComplete='off'
														/>
														{/* <input type="text" className="border_none" style={{width:"158px", height:"100%", margin:"auto 0 auto 20px", padding:"0", verticalAlign:"top"}}></input> */}
													</div>
													{
														this.state.start_date != null && this.state.start_date != ""
														?	<div className="input_v3_btn_close" style={{position:"absolute", right:"62px", top:"3px"}} onClick={e => this.handleInitDate("start_date")}></div>
														:	""
													}
													<div className="icn_calendar_v2 cursor-pointer" style={{margin:"auto 0 auto 15px"}} onClick={e => this.onClickStartDateInputBox(e)}></div>	
												</div>
												<div className="flexBox m-t-10 m-l-20" style={{position:"relative"}}>
													<div style={{fontSize:"13px"}}>
														종료																	
													</div>
													<div className="datePickerArea" style={{height:"18px"}}>
													<DatePicker name="end_date" id="end_date"
														selected={this.state.end_date}
														onSelect={this.handleSelect} //when day is clicked
														onChange={this.handleChangeEndDate} //only when value has changed
														// showTimeSelect
														dateFormat="yyyy-MM-dd"
														className={["border_none_input_calendar","list_datePicker"].join(' ')}
														autoComplete='off'
														/>
														{/* <input type="text" className="border_none" style={{width:"158px", height:"100%", margin:"auto 0 auto 20px", padding:"0", verticalAlign:"top"}}></input> */}
													</div>
													{
														this.state.end_date != null && this.state.end_date != ""
														?	<div className="input_v3_btn_close" style={{position:"absolute", right:"62px", top:"3px"}} onClick={e => this.handleInitDate("end_date")}></div>
														:	""
													}
													<div className="icn_calendar_v2 cursor-pointer" style={{margin:"auto 0 auto 15px"}} onClick={e => this.onClickEndDateInputBox(e)}></div>	
												</div>
											</div>

											<div id="banner_list_date_area" className="border_form_radius_d" style={{flexDirection:"column", left:"0", top:"83px", width:"280px", height:"auto", display:"flex", padding:"0", marginTop:"20px"}}>
												<div className="m-t-20 m-l-20" style={{fontSize:"15px"}}>
													<b>진열여부</b>																		
												</div>
												<div className="m-t-16 m-l-20 m-b-20">
													<div id="list_filter_display_all" className="list_filter_display_yn list_filter_type_text_on m-t-10 cursor-pointer" onClick={this.onClickDisplayYnFilter}>전체</div>
													<div id="list_filter_display_y" className="list_filter_display_yn list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayYnFilter}>진열중</div>
													<div id="list_filter_display_n" className="list_filter_display_yn list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayYnFilter}>미진열</div>
												</div>
											</div>
											<div id="banner_list_date_area" className="border_form_radius_d" style={{flexDirection:"column", left:"0", top:"83px", width:"280px", height:"auto", display:"flex", padding:"0", marginTop:"20px"}}>
												<div className="m-t-20 m-l-20" style={{fontSize:"15px"}}>
													<b>진열대상</b>																		
												</div>
												<div className="m-t-16 m-l-20 m-b-20">
													<div id="list_filter_all_user" className="list_filter_display_user list_filter_type_text_on m-t-10 cursor-pointer" onClick={this.onClickDisplayUserFilter}>모두</div>
													<div id="list_filter_only_user" className="list_filter_display_user list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayUserFilter}>회원</div>
													<div id="list_filter_none_user" className="list_filter_display_user list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayUserFilter}>비회원</div>
													<div id="list_filter_target_view" className="list_filter_display_user list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayUserFilter}>타겟뷰</div>
													<div id="list_filter_default" className="list_filter_display_user list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickDisplayUserFilter}>기본배너</div>
												</div>
											</div>
											<div id="banner_list_date_area" className="border_form_radius_d" style={{flexDirection:"column", left:"0", top:"83px", width:"280px", height:"auto", display:"flex", padding:"0", marginTop:"20px"}}>
												<div className="m-t-20 m-l-20" style={{fontSize:"15px"}}>
													{/* <b>자동 배너 / 직접 제작 배너</b>																		 */}
													<b>배너 종류</b>																		
												</div>
												<div className="m-t-16 m-l-20 m-b-20">
													<div id="list_filter_all_banner" className="list_filter_auto_banner list_filter_type_text_on m-t-10 cursor-pointer" onClick={this.onClickAutoBannerYnFilter}>전체</div>
													<div id="list_filter_auto_banner" className="list_filter_auto_banner list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickAutoBannerYnFilter}>자동 배너</div>
													<div id="list_filter_manual_banner" className="list_filter_auto_banner list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickAutoBannerYnFilter}>로보 배너</div>
													<div id="list_filter_my_banner" className="list_filter_auto_banner list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickAutoBannerYnFilter}>마이 배너</div>
													<div id="list_filter_sample_banner" className="list_filter_auto_banner list_filter_type_text m-t-10 cursor-pointer" onClick={this.onClickAutoBannerYnFilter}>샘플 배너</div>
												</div>
											</div>
										</div>
									</div>
								: null
	  						}
						
							{/* 테이블 */}
							<div className={
												this.state.solution_type_cd == "09900" 
												? "banner_list_table_area_parent margin-left-0-important" 
												: "banner_list_table_area_parent"
											} 
									style={{width:"calc(95vw - 600px)", flex:"1", height: "auto", overflowX:"auto", overflowY: "hidden", marginLeft:"95px"
							}}>
								<div className="banner_list_table_area" style={{width:"100%", minWidth:"1500px", height:"100%"}}>
									<div className="banner_list_table_area" style={{display:"inline-block", height:"100%", minWidth:"1800px", width:"100%"}}>
										<div className="table table_V2" style={{flex:"1", height:"100%"}}>
											<table>
												{/* <colgroup>
													<col width="50px" />
													<col width="*" />
													<col width="50px" />
													<col width="80px" />
													<col width="10%" />
													<col width="10%" />
													<col width="10%" />
													<col width="80px" />
												</colgroup> */}
												<thead>	
													<tr>
														<td class="use_pointer_events" style={{width:"50px"}}>
															<CheckBox
																id={"list_auto_event_checkbox_all"}
																checked={this.state.checked_auto_event_list.length > 0 && (this.state.checked_auto_event_list.length == this.state.event_list.length)}
																onChangeCheck={this.checkAllAutoEventListOnChange.bind(this)}
															/>
														</td>
														<td style={{display:"none"}}>
															<p className="card-text">이벤트 번호</p>
														</td>
														<td style={{width:"50px"}}>
															<p className="card-text">No.</p>
														</td>
														<td style={{width:"100px"}}>
															<p className="card-text">배너 미리보기</p>
														</td>
														<td style={{width:"200px"}}>
															<p className="card-text" style={{textAlign:"left"}}>배너 이름</p>
														</td>
														{/* <td style={{width:"130px"}}>
															<p className="card-text">배너 페이지</p>
														</td> */}
														<td style={{width:"130px"}}>
															<p className="card-text">배너 영역</p>
														</td>
														<td className="event_view_cnt sorting" style={{width:"100px"}} onClick={this.onClickSorting}>
															<div style={{display:"flex"}}>
															<p className="card-text">클릭수</p>
																{(() => {
																	if (this.state.orderGubun == "event_view_cnt") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td style={{width:"80px"}}>
															<p className="card-text">진열 대상</p>
														</td>
														<td className="start_date sorting" style={{width:"10.5%"}} onClick={this.onClickSorting}>
															<div style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>
																<p className="card-text">시작일시</p>
																{(() => {
																	if (this.state.orderGubun == "start_date") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td className="end_date sorting" style={{width:"10.5%"}} onClick={this.onClickSorting}>
															<div style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>
																<p className="card-text">종료일시</p>
																{(() => {
																	if (this.state.orderGubun == "end_date") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td className="reg_date sorting" style={{width:"10.5%"}} onClick={this.onClickSorting}>
															<div style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>
																<p className="card-text">최초 등록일</p>
																{(() => {
																	if (this.state.orderGubun == "reg_date") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td className="mod_date sorting" style={{width:"10.5%"}} onClick={this.onClickSorting}>
															<div style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>
																<p className="card-text">최종 수정일</p>
																{(() => {
																	if (this.state.orderGubun == "mod_date") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td className="display_yn sorting" style={{width:"115px"}} onClick={this.onClickSorting}>
															<div style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>
																<p className="card-text">진열 여부</p>
																{(() => {
																	if (this.state.orderGubun == "display_yn") {
																		if(this.state.sorting == "ASC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_b"></div> 
																				</>
																			)
																		} else if (this.state.sorting == "DESC") {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_b"></div> 
																					<div className="icn_table_v2_sort_asc_arrow_d"></div>
																				</>
																			)
																		} else {
																			return ( 
																				<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																			)
																		}
																	} else {
																		return ( 
																			<>
																					<div className="icn_table_v2_sort_desc_arrow_d"></div>
																					<div className="icn_table_v2_sort_asc_arrow_d"></div> 
																				</>
																		)
																	}
																})()}
															</div>
														</td>
														<td style={{width: "120px"}}>
															<p className="card-text" style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>슬라이드 여부</p>
														</td>
														<td style={{width: "120px"}}>
															<p className="card-text" style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너 복제</p>
														</td>
														<td style={{width: "120px"}}>
															<p className="card-text" style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너 삭제</p>
														</td>
														<td style={{width: "120px"}}>
															<p className="card-text" style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>다운로드</p>
														</td>
													</tr>
												</thead>
												<tbody>
													{
														this.state.is_ten_cnt_error 
														? 	<tr>
																<td style={{width:"100%",  background:"#FFEEF2",borderTop:"1.5px solid #FFAEC2", borderBottom:"1.5px solid #FFAEC2"}}>
																	<p id="title" className="card-text" style={{display: "flex" ,textAlign:"left", margin:"auto 0 auto 0"}}>
																		<div className="icn_warning_round_red" style={{marginLeft:"4.2px"}}></div>
																		<span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", marginLeft:"12px"}}>
																			{"로보 배너는 10개까지 진열 가능합니다."}
																		</span>
																	</p>
																</td>
															</tr>
														: <></>
													}
													{
														this.state.is_thirty_cnt_error 
														? 	<tr>
																<td style={{width:"100%",  background:"#FFEEF2",borderTop:"1.5px solid #FFAEC2", borderBottom:"1.5px solid #FFAEC2"}}>
																	<p id="title" className="card-text" style={{display: "flex" ,textAlign:"left", margin:"auto 0 auto 0"}}>
																		<div className="icn_warning_round_red" style={{marginLeft:"4.2px"}}></div>
																		<span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", marginLeft:"12px"}}>
																			{"자동 배너는 30개까지 진열 가능합니다."}
																		</span>
																	</p>
																</td>
															</tr>
														: <></>
													}
													{this.state.event_list.map((event, idx) => (
														<tr key={event.event_seq} className="cursor-pointer tbody-tr-hover">
															<td style={{width:"50px"}}>
																<CheckBox
																	id={"list_auto_event_checkbox_" + (Number(idx))}
																	checked={this.state.checked_auto_event_list.find(auto => auto.event_seq == event.event_seq)}
																	onChangeCheck={e => this.checkOneAutoEventListOnChange(e, event)}
																/>
															</td>
															<td style={{display:"none"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">{event.event_seq}</p>
															</td>
															<td style={{width:"50px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</p>
															</td>
															<td style={{width:"100px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<Icon text={"미리보기"} onClick={e => this.onClickMirrorButton(e, event.event_seq, event.event_banner_pc_width_height, event.event_banner_mobile_width_height, event.event_type_cd, event.event_mobile_type_cd)}>
																	<img src={preview_icon} style={{width:"24px", height:"24px"}}/>
																</Icon>
															</td>
															{
															this.state.solution_type_cd == "09900" 
															?
																<td style={{width:"200px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																	<p id="title" className="card-text" style={{display:"flex", fontSize:"12.5px", textAlign:"left", margin:"auto 0 auto 0"}}>
																		{event.title}
																	</p>
																</td>
															: 	
																<td style={{width:"200px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																	<p id="title" className="card-text" style={{display:"flex", fontSize:"12.5px", textAlign:"left", margin:"auto 0 auto 0"}}>
																		{
																			event.reg_user_seq == -1
																			? <>
																				<span style={{color:"#10CFC9",margin:"auto 0"}}><b>자동 배너</b></span><span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", margin:"6px"}}>{event.title}</span>
																			</>
																			: 	event.mod_user_seq == -2
																				?	<>
																						<span style={{color:"#5898fe",margin:"auto 0"}}><b>샘플 배너</b></span><span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", margin:"6px"}}>{event.title}</span>
																					</>
																				:
																					event.event_promotion_type_cd != null && event.event_promotion_type_cd == "19002"
																					?	<>
																							<span style={{color:"RGB(186 172 254)",margin:"auto 0"}}><b>마이 배너</b></span><span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", margin:"6px"}}>{event.title}</span>
																						</>
																					: 	
																						<>
																							<span style={{color:"#000080",margin:"auto 0"}}><b>로보 배너</b></span><span style={{flex:"1", overflowX:"hidden", textOverflow:"ellipsis", whiteSpace: "nowrap", margin:"6px"}}>{event.title}</span>
																						</>	
																		}
																	</p>
																</td>
	  														}
															{/* <td style={{width:"130px"}}>
																<p className="card-text">{event.iframe_page_id}</p>
															</td> */}
															<td style={{width:"130px"}}>
																<p className="card-text">{event.iframe_html_name}</p>
															</td>
															<td style={{width:"100px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">{event.event_view_cnt}</p>
															</td>
															<td style={{width:"80px"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">
																	{
																		event.item_user_type == "U"
																		? 	"회원"
																		: 	<> 
																				{
																					event.item_user_type == "N"
																					? "비회원"
																					: event.item_user_type == "T"
																						? "타겟뷰"
																						: event.item_user_type == "D"
																							? "기본배너"
																							: "모두"
																				}
																			</>	
																	}
																</p>
															</td>
															<td style={{width:"10.5%"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">{event.start_date}</p>
															</td>
															<td style={{width:"10.5%"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																<p className="card-text">{event.end_date}</p>
															</td>
															<td style={{width:"10.5%"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																{/* 띄어쓰기에서 줄바꿈 처리 */}
																<p className="card-text" dangerouslySetInnerHTML={ {__html: event.reg_date != null ? event.reg_date.replace(" ", "<br/>") : ""} }></p>
															</td>
															<td style={{width:"10.5%"}} onClick={() => this.handleClick(event.event_seq, event.event_promotion_type_cd, event.mod_user_seq)}>
																{/* 띄어쓰기에서 줄바꿈 처리 */}
																<p className="card-text" dangerouslySetInnerHTML={ {__html: event.mod_date != null ? event.mod_date.replace(" ", "<br/>") : ""} }></p>
															</td>
															<>
																{(() => {
																	if ((this.state["display_yn_"+event.event_seq] == null && event.display_yn == "Y") || this.state["display_yn_"+event.event_seq] == "Y") {
																		return (<td style={{width:"113px"}} onClick={e => this.onClickEventDisplay(e, event.event_seq, "display_yn_"+event.event_seq, "N", event.start_date, event.end_date, event.iframe_page_id, event.iframe_html_id, event.item_user_type, event.reg_user_seq, event.mod_user_seq, event.event_period, idx, event.display_yn)}>
																					<div>
																						<p className="card-text btn_robomd_recomendation_back" style={{width:"92px", height:"40px", position: "inherit"}}>
																							<p className="card-text btn_robomd_recomendation_on_front" style={{width:"70px", height:"40px", lineHeight: "41px", position: "inherit"}}>진열중</p>
																						</p>
																					</div>
																				</td>)
																	} else if ((this.state["display_yn_"+event.event_seq] == null && event.display_yn == "R") || this.state["display_yn_"+event.event_seq] == "R") {
																		return (<td style={{width:"113px"}} onClick={e => this.onClickEventDisplay(e, event.event_seq, "display_yn_"+event.event_seq, "N", event.start_date, event.end_date, event.iframe_page_id, event.iframe_html_id, event.item_user_type, event.reg_user_seq, event.mod_user_seq, event.event_period, idx, event.display_yn)}>
																					<div>
																						<p className="card-text btn_robomd_recomendation_back" style={{width:"92px", height:"40px", position: "inherit"}}>
																							<p className="card-text btn_robomd_recomendation_ready_front" style={{width:"70px", height:"40px", lineHeight: "41px", position: "inherit"}}>예약중</p>
																						</p>
																					</div>
																				</td>	)
																	} else {
																		return (<td style={{width:"113px"}} onClick={e => this.onClickEventDisplay(e, event.event_seq, "display_yn_"+event.event_seq, "Y", event.start_date, event.end_date, event.iframe_page_id, event.iframe_html_id, event.item_user_type, event.reg_user_seq, event.mod_user_seq, event.event_period, idx, event.display_yn)}>
																					<div>
																						<p className="card-text btn_robomd_recomendation_back" style={{width:"92px", height:"40px", position: "inherit"}}>
																							<p className="card-text btn_robomd_recomendation_off_front" style={{width:"70px", height:"40px", lineHeight: "41px", position: "inherit", marginLeft:"22px"}}>미진열</p>
																						</p>
																					</div>
																				</td>	)
																	}
																})()}
															</>
															<td style={{width: "120px"}}>
																<p className="card-text">{event.slide_yn == "Y" ? "여러 이미지" : "단일 이미지"}</p>
															</td>
															<td style={{width: "120px"}}>
																<Icon className={"btn_clone_auto_event"} text={"배너 복제"} children={<img src={icn_clone} alt={"배너 복제"}/>} onClick={(e) => {this.cloneAutoEventListOnClick(e, event)}}>
																</Icon>
															</td>
															<td style={{width: "120px"}}>
																<Icon className={"btn_del_auto_event"} text={"배너 삭제"} children={<img src={icn_delete} alt={"배너 삭제"}/>} onClick={(e) => {this.deleteOneAutoEventListOnClick(e, event)}}>
																</Icon>
															</td>
															<td style={{width: "120px"}}>
																<button className={"btn_download_auto_event"} onClick={(e) => {this.downloadOneEventListBtnClick(e, event)}} style={{margin:"0 auto"}}>
																	{this.state.is_banner_download == false && this.state.download_event_seq == event.event_seq
																		?
																		<div className="list_loading list_loading_download" style={{alignSelf: "center"}}>
																			<span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
																		</div>
																		:
																		<Icon className={"btn_download_auto_event_" + event.event_seq} text={"다운로드"} children={<img src={icn_download} alt={"다운로드"}/>}>
																		</Icon>
																	}
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="banner_list_paging_area" style={{position:"relative", width: "calc(100% - 380px)", marginTop:"10px", marginLeft:"380px"}}>
							<div id="list_info_area" style={{position:"absolute", top:"0", right:"0"}}>
								<div style={{position:"relative", top:"0", marginTop:"0", fontSize:"13px"}}>
									<div className="margin-vertical-auto flexBox">
											<div className="icn_warning_round"></div>
											<div style={{marginLeft:"10px", flex:"1"}}>
												<b style={{"color": "rgb(16, 207, 201)"}}>자동 배너</b>는 최대 <b style={{"color": "rgb(16, 207, 201)"}}>30개</b>까지 진열할 수 있습니다.
											</div>
									</div>									
								</div>
								<div style={{position:"relative", top:"5px", marginTop:"0", fontSize:"13px"}}>
									<div className="margin-vertical-auto flexBox">
											<div className="icn_warning_round"></div>
											<div style={{marginLeft:"10px", flex:"1"}}>
												<b style={{"color": "rgb(16, 207, 201)"}}>로보 배너</b>는 최대 <b style={{"color": "rgb(16, 207, 201)"}}>10개</b>까지 진열할 수 있습니다.
											</div>
									</div>									
								</div>
								{/* <div style={{position:"relative", top:"10px", marginTop:"0", fontSize:"13px"}}>
									<div className="margin-vertical-auto flexBox">
											<div className="icn_warning_round"></div>
											<div style={{marginLeft:"10px", flex:"1"}}>
												<b style={{"color": "rgb(16, 207, 201)"}}>자동 배너</b>를 수정하면 <b style={{"color":"rgb(16, 207, 201)"}}>로보 배너</b>로 변경됩니다.
											</div>
									</div>									
								</div> */}
							</div>

							<div>
								<Pagination
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.rowcnt}
									totalItemsCount={this.state.totalCnt}
									pageRangeDisplayed={5}
									onChange={this.handlePageChange.bind(this)}
								/>
							</div>
						</div>
						<div class="event_order_change_popup style_guide_v2">
							{/* 배너 순서 변경 설정 팝업 */}
							{this.state.show_order_popup
							?
							<PopupFrame
								title={"배너 순서 변경"}
								titleFontSize={"20px"}
								maxWidth={"950px"}
								headerMarginBottom={"30px"}
								onClose={this.onClickCloseOrderChangePopup.bind(this)}
							>
								<div style={{width:"100%"}}>
									<h2 class="text_16" style={{marginBottom: "5px"}}>배너 페이지 종류</h2>
									<SelectBoxV2
										id={"order_popup_page_id"}
										tabIndex={0}
										width="100%"
										height="40px"
										padding="10px 36px 10px 16px"
										list= {this.state.iframe_page_id_list}
										optionTexttName = "val1"
										optionValuetName = "code_name"
										selectedValue = {this.state.order_iframe_page_id}
										selectedText={this.state.order_iframe_page_id != null && this.state.order_iframe_page_id != "" ? this.getSelectBoxSelectIdx(this.state.iframe_page_id_list, "val1","code_name",this.state.order_iframe_page_id): "선택해 주세요."}
										onClickOption={this.onChangeSelectOrderPageId.bind(this)}
									/>
									<h2 class="text_16" style={{margin: "20px 0px 5px 0px"}}>배너 영역 종류</h2>
									<SelectBoxV2
										id={"order_popup_html_id"}
										tabIndex={0}
										width="100%"
										height="40px"
										padding="10px 36px 10px 16px"
										list= {this.state.order_iframe_html_id_list}
										optionTexttName = "iframe_html_name"
										optionValuetName = "iframe_html_id"
										selectedValue = {this.state.order_iframe_html_id}
										selectedText={this.state.order_iframe_html_id != null && this.state.order_iframe_html_id != "" ? this.getSelectBoxSelectIdx(this.state.order_iframe_html_id_list, "iframe_html_name","iframe_html_id",this.state.order_iframe_html_id): "선택해 주세요."}
										onClickOption={this.onChangeSelectOrderHtmlId.bind(this)}
									/>
									<button class="hover_grey banner_order_change_btn" style={{margin: "20px auto 20px auto", border: "1px solid #ACACAC"}} onClick={() => this.onClickSearchOrderList()}>검색</button>
									{/* 테이블 */}
									<h2 class="text_16" style={{marginBottom:"5px"}}>
										{this.state.search_iframe_page_id != null && this.state.search_iframe_page_id != "" && this.state.search_iframe_html_id != null && this.state.search_iframe_html_id != ""
										?	this.state.iframe_page_id_list.find((obj) => obj.code_name == this.state.search_iframe_page_id)["val1"]
											+ " / "
											+ this.state.search_iframe_html_id_list.find((obj) => obj.iframe_html_id == this.state.search_iframe_html_id)["iframe_html_name"]
										:	""}
									</h2>
									<div style={{width:"100%", flex:"1", height: "auto", overflowX:"auto", overflowY: "hidden", marginLeft:"0px"}}>
										<div class="banner_list_table_area" style={{width:"100%", minWidth:"900px", height:"100%"}}>
											<div class="banner_list_table_area" style={{display:"inline-block", height:"100%", minWidth:"900px", width:"100%"}}>
												<div className="table table_V2 banner_order_change_table" style={{flex:"1", height:"100%"}}>
													<List
														values={this.state.order_popup_event_list}
														// 드래그를 통한 순서 변경시
														onChange={({ oldIndex, newIndex }) =>{
															// arrayMove 함수를 통해 자동으로 순서가 변경된다.
															const new_order_popup_event_list = [...arrayMove(this.state.order_popup_event_list, oldIndex, newIndex)];
															this.setState({
																order_popup_event_list : new_order_popup_event_list,
																order_list_is_modified : true,
															});
														}}
														renderList={({ children, props, isDragged  }) => 
															<table>
																<thead>
																	<tr>
																		<td><p className="card-text">번호</p></td>
																		<td><p className="card-text">순서번경</p></td>
																		<td><p className="card-text">배너 제목</p></td>
																		<td><p className="card-text">진열 여부</p></td>
																	</tr>
																</thead>
																{this.state.search_iframe_page_id != null && this.state.search_iframe_page_id != "" && this.state.search_iframe_html_id != null && this.state.search_iframe_html_id != ""
																? this.state.show_order_popup_loading_bar
																	?<tbody style={{position: "relative", height:"200px"}}>  
																		<div style={{width:$(".banner_order_change_table").width(), height:"200px",position: "absolute",display:"flex",justifyContent:"center"}}>
																			<img src={round_loading_bar} style={{width:"200px"}}></img>
																		</div>
																	</tbody>
																	:<tbody {...props} id="order_change_table_tbody" style={{height:"auto"}}>{children}</tbody>
																: <div style={{textAlign: "center", height: "100px", paddingTop: "40px", fontSize: "16px"}}>
																	배너페이지 종류와 배너 영역 종류를 선택 후 검색을 누르세요.
																</div>}
															</table>
														}
														renderItem={({ value, props, isDragged, isSelected, index }) => {
															const row = (
																<tr {...props}
																style={{
																	...props.style,
																	cursor: isDragged ? 'grabbing' : 'grab',
																	height: "600 !important",
																	overflowY: 'scroll !important',
																	overflowX: 'hidden !important',
																}}
																key={value.event_seq}
																tabIndex={-1} >
																	<td>
																		<input class="list_table_number" type="text" style={isDragged ? {backgroundColor:"#FFFFFF", padding:'0px', height: "24px"} : {backgroundColor:"#FFFFFF", padding:'0px', height: "24px"}}
																		value={
																			isDragged ? " " : this.state.order_event_idx[index]
																		} name={index} id={"order_change_input_"+index} onChange={e => this.onChangeOrderInput(e)} onKeyPress={e =>this.onChangeOrderIdx(e,false)}
																		onBlur={e => this.onChangeOrderIdx(e,true)} autocomplete="off"/>
																	</td>
																	<td id={"drag_btn_"+index} data-movable-handle style={isDragged ? {cursor: "pointer"} : {cursor: "pointer"}} tabIndex={-1}>
																		<svg class="drag_btn" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M6.69529 10.3048C6.81265 10.4233 6.87849 10.5833 6.87849 10.7501C6.87849 10.9169 6.81265 11.0769 6.69529 11.1954L4.19529 13.6954C4.07583 13.8109 3.91615 13.8755 3.74998 13.8755C3.5838 13.8755 3.42413 13.8109 3.30466 13.6954L0.804664 11.1954C0.704999 11.074 0.654067 10.9198 0.661773 10.7629C0.669479 10.606 0.735273 10.4576 0.846358 10.3465C0.957442 10.2354 1.10587 10.1696 1.26278 10.1619C1.41969 10.1542 1.57385 10.2051 1.69529 10.3048L3.12498 11.7423V0.750098C3.12498 0.584338 3.19082 0.425366 3.30803 0.308156C3.42524 0.190946 3.58422 0.125098 3.74998 0.125098C3.91574 0.125098 4.07471 0.190946 4.19192 0.308156C4.30913 0.425366 4.37498 0.584338 4.37498 0.750098V11.7423L5.80466 10.3048C5.92316 10.1874 6.0832 10.1216 6.24998 10.1216C6.41676 10.1216 6.57679 10.1874 6.69529 10.3048ZM14.1953 2.80479L11.6953 0.304785C11.5768 0.187422 11.4168 0.121582 11.25 0.121582C11.0832 0.121582 10.9232 0.187422 10.8047 0.304785L8.30466 2.80479C8.205 2.92622 8.15407 3.08038 8.16177 3.23729C8.16948 3.3942 8.23527 3.54263 8.34636 3.65372C8.45744 3.7648 8.60587 3.83059 8.76278 3.8383C8.91969 3.84601 9.07385 3.79507 9.19529 3.69541L10.625 2.25791V13.2501C10.625 13.4159 10.6908 13.5748 10.808 13.692C10.9252 13.8092 11.0842 13.8751 11.25 13.8751C11.4157 13.8751 11.5747 13.8092 11.6919 13.692C11.8091 13.5748 11.875 13.4159 11.875 13.2501V2.25791L13.3047 3.69541C13.4241 3.81092 13.5838 3.8755 13.75 3.8755C13.9162 3.8755 14.0758 3.81092 14.1953 3.69541C14.3127 3.57691 14.3785 3.41688 14.3785 3.2501C14.3785 3.08332 14.3127 2.92328 14.1953 2.80479Z" fill="#333333"/>
																		</svg>
																	</td>
																	<td>{value.title}</td>
																	{value.display_yn == "Y"
																	?<td style={{color: "rgb(16, 207, 201)"}}>진열중</td>
																	:<td style={{color: "#B7BDC3"}}>미진열</td>}
																</tr>
															);
															return isDragged ? (
																<div class="add_btn_onclick style_guide_v2" style={{background:"none", zIndex:"10000"}}>
																	<div>
																		<div>
																			<div>
																				<div>
																					<div>
																						<div className="table table_V2 banner_order_change_table">
																							<table>
																								<tbody style={{height:"auto"}}>{row}</tbody>
																							</table>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															) : (
																row
															);
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									{this.state.order_list_is_modified
									?<button class="hover_mint banner_order_change_btn" style={{marginTop:"10px"}} onClick={() => this.saveEventOrderList()}>저장</button>
									:<button class="hover_mint disabled banner_order_change_btn" style={{marginTop:"10px"}}>저장</button>}
								</div>
							</PopupFrame>
							: ""}
						</div>
					</div>
					{ 
						this.state.isOtdealUser == true && this.state.isCreate == false
						? <div className="greenButton cursor-pointer" style={{width:"92px", height:"30px", lineHeight:"28px", textAlign:"center", margin:"0 auto 15px auto"}} onClick={this.onClickCreateOtdealBanner}>
							옷딜용 배너생성
						</div>
						: ""  
					}
					{
						this.state.isCreate 
						? <div style={{width:"100px", height:"100px", margin:"0 auto 15px auto"}}>
							<img src={"https://img.otdeal.net/trend/loading_spinner.gif"} style={{width:"100px", height:"100px"}}></img>
						</div>
						: ""
					}

					{/* 미리보기 팝업 */}
					<div className="mirror_banner_modal_v2" style={{visibility: "visible"}}>
						<div className="mirror_banner_v2_inner_area">
							<div className="mirror_banner_v2_title">
								<div style={{flex: 100}}>
								미리보기
								</div>
								<div>
									<div className="btn_close_v2 cursor-pointer" onClick={e => this.onClickMirrorBannerOff(e)}></div>
								</div>
							</div>
							<div className="mirror_banner_v2_body" style={{position: "relative"}}>
								{ 
									(this.state.event_banner_pc_width_height != null && Number(this.state.event_banner_pc_width_height) > 1) 
									|| (this.state.event_banner_mobile_width_height != null && Number(this.state.event_banner_mobile_width_height) > 1)
									?	<div className="mirror_banner_v2_pc_banner_area" style={{position:"relative"}}>
											<div className="flexBox m-b-10" style={{height: "43px"}}>
												<label style={{margin:"auto 0 auto 0",}}><b>가로형</b></label>
											</div>   
											<div className="mirror_banner_v2_pc_banner">

											</div>
											{
												this.state.event_type_cd == null || this.state.event_type_cd == ""
												?	<div class="flexBox curtain" style={{top: "0", width:"100%", background:"#585858cc", color:"white"}}>
														<div style={{margin:"auto", textAlign:"center"}}>
														가로형 배너 스타일을 선택해 주세요.
														</div>
													</div>
												: 	""
											}
										</div>
									: ""
								}
								{
									(this.state.event_banner_pc_width_height != null && Number(this.state.event_banner_pc_width_height) <= 1) 
									|| (this.state.event_banner_mobile_width_height != null && Number(this.state.event_banner_mobile_width_height) <= 1)
									?    <div className="mirror_banner_v2_mobile_banner_area" style={{position:"relative"}}>
											<div className="flexBox m-b-10" style={{height: "43px"}}>
												<label style={{margin:"auto 0 auto 0",}}><b>세로형</b></label>
											</div>
											<div className="mirror_banner_v2_mobile_banner">
												
											</div>
											{
												this.state.event_mobile_type_cd == null || this.state.event_mobile_type_cd == ""
												?	<div class="flexBox curtain" style={{top: "0", width:"100%", background:"#585858cc", color:"white"}}>
														<div style={{margin:"auto", textAlign:"center"}}>
														세로형 배너 스타일을 선택해 주세요.
														</div>
													</div>
												: 	""
											}
										</div>
									: ""
								}
							</div>
							<div className="flexBox width-100">
							</div>
							<br/>
							<font style={{margin:"auto", color:"#bfc4d6", fontSize:"10px", lineHeight:"10px"}}>(실제 적용된 모습과 다를 수 있습니다. 참고용으로 사용해 주세요.)</font>
						</div>
					</div>

					{/* 팝업창 배경 */}
					<div id="modalBack" style={{top: "0", height:"130%"}}></div>
				</div>

				{/* 진열여부 설정 팝업 
					1. popup_id : "common_popup1"		(div id 부여)
					2. width : "450px" 					(최소 450px)
					3. content : "팝업 내용<br/>예제 입니다."
					4. button_list : 
						[
							{
								title: "버튼명",			  // 버튼명
								type: "1",					// 버튼 (컬러)타입 : "1" (#10CFC9), "0" (#FFFFFF) 
								event_method: () => {
									//이벤트 함수 구현
									...
								}
							},
						]
					5. button_close : 닫기 함수 구현
				*/}
				{
					(this.state.common_popup1 === true)
					? <CommonPopup 
						popup_id={"common_popup1"}
						width={"450px"}
						content={this.state.common_popup1_content}
						button_list={this.state.common_popup1_button_list}
						popup_close={() => this.setState({common_popup1: false})}
					/>
					: ''
				}
				{
					(this.state.delete_dialog_show_state === true)
						? <ConfirmDialogV2
							content={this.state.delete_dialog_content}
							button_list={this.state.delete_dialog_button_list}
							popup_close={() => {
								this.setState({delete_dialog_show_state: false});
							}}
						/>
						: ''
				}
				{
					(this.state.basic_dialog_show_state === true)
						? <BasicDialogV2
							content={this.state.basic_dialog_content}
							button_list={this.state.basic_dialog_button_list}
						/>
						: ''
				}
				{
					(this.state.title_content_dialog_show_state === true)
					? <TitleContentDialogV2
                        title={this.state.title_content_dialog_title}
						content={this.state.title_content_dialog_content}
						button_list={this.state.title_content_dialog_button_list}
						popup_close={() => {
                            this.setState({title_content_dialog_show_state: false});
                        }}
					/>
					: ''
				}
			</>
		);
	}
}
