import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import plus_img from '../../resources/img/plus.svg';
import Pagination from "react-js-pagination";
import FileDownload from 'js-file-download';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

var is_product_loaded = false;

export class KdealProductList extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            // 무료 체험 기간 관련 변수
            remain_date: "",
            start_day_of_week: "",
            start_date_format: "",
            is_finish: "",
            end_day_of_week: "",
            end_date_format: "",

            list : [], // 진열 목록
            all_install_yn : true, // 모든 진열이 설치됐는지에 대한 여부(하나라도 설치되지 않았다면 false)

            /* 페이징 */
            activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			rowcnt:10,

            kdeal_user_no: "",

            is_downloaded: [],
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        $(document).ready(function(){
            //table의 넓이 계산
            var list_td = document.getElementsByClassName("list_td");
            var list_td_sum = 0;
            
            for(let i = 0; i < list_td.length; i++){
                list_td_sum = list_td_sum + list_td[i].offsetWidth;
            }

            $(".list_home_main_row_3 table").css({
                "width":list_td_sum
            });
        });

        this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, "", "");
        // this.loadKdealShopList();
        // this.loadFreeDatePeriod();
    }

    componentDidUpdate() {
    }

    componentWillUnmount(e) {

    }

    getUrlParams() {
        var params = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params;
    }

    // setGaEvent(action_text) {
    //     ReactGA.event({
    //          category: "베스트 서비스 (롤링 목록)",
    //          action: action_text,
    //          label: sessionStorage.getItem("user_id"),
    //      });
    // }

    // 소상공인 상품 목록 데이터 불러오기
    async loadKdealProduct(startIdx, rowcnt, keyword, kdeal_user_no) {
        let param = "?";
        param += "&startidx="+startIdx;
        param += "&rowcnt="+rowcnt;
        param += "&keyword="+keyword;
        param += "&kdeal_user_no="+kdeal_user_no;

        // const formData = new FormData();
        // formData.append('startidx',startIdx);
        // formData.append('rowcnt',rowcnt);
        // formData.append('keyword',keyword);
        is_product_loaded = false;

        await http.get("/rest/shop/kdeal/product/list"+param)
		.then(res => {
            is_product_loaded = true;
            console.log(res.data);
			if (res.data != null && res.data != '') {
                let is_downloaded = [];
                for(let idx in res.data["list"]) {
                    is_downloaded[idx] = true;
                }
				this.setState({
                    list : res.data["list"],
                    totalCnt : res.data["totalCnt"],
                    is_downloaded: is_downloaded
                });
			}
		})
		.catch(error => {
		 });
    }

    // 소상공인 업체 목록 데이터 불러오기
    async loadKdealShopList() {
        await http.get("/rest/common/kdeal/user/list")
		.then(res => {
            console.log(res.data);
			if (res.data != null && res.data != '') {
				this.setState({
                    shop_list : res.data,
                    // totalCnt : res.data["totalCnt"],
                });
			}
		})
		.catch(error => {
		 });
    }

    // 상세 페이지로 이동
    onClickProductPageLink(e, product_no) {
        // 무료 체험 기간에만 이동 가능하도록 변경
        if(this.state.is_finish == 0){
            // this.setGaEvent("목록 클릭 - "+ html_name);
            this.props.history.push(
                {
                    pathname: `/shop/kdeal/sosanggongin`,
                    search:`?product_no=`+product_no,
                }	
            )
        }
    }

    onClickAddList(e){
        // this.setGaEvent("진열 추가 클릭");
        this.props.history.push(
            {
                pathname: `/shop/kdeal/sosanggongin`,
            }	
        )
    }

    // 페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
	}  

    // 엔터키 검색
	_handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.state.startIdx = 0;
            this.state.activePage = 1;
            this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
        }
    }

    // 이미지 다운로드 클릭
    onClickContentDownloadBtn(e, idx, product_no, product_name) {
        e.stopPropagation();

        let is_downloaded = [...this.state.is_downloaded]
        for(let i in is_downloaded) {
            if (!is_downloaded[i]) {
                alert("하나씩 다운로드 해주세요.")
                return false;
            }
        }

        is_downloaded[idx] = false;
        this.setState({
            is_downloaded: is_downloaded,
        })
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
        }
        
        http.post("/rest/common/kdeal/content/capture",
            {
                "product_no" : product_no,
                "banner_width": "960",
                "view_type_cd": "2"
            },
            config
        )
        .then(res => {
            let is_downloaded = [...this.state.is_downloaded]
            is_downloaded[idx] = true;
            this.setState({
                is_downloaded: is_downloaded,
            })

            if (res.data.code == "200") {
                // 성공시 s3 파일 다운로드 요청
                this.fn_s3ImageFileDownload(res.data.capture_code, product_name);
            } else {
                alert(res.data.code + "\n" + res.data.msg);
            }
        })
        .catch(error => {
            console.log (error);
            this.setState({
                is_downloaded: false,
            })
        });
    }

    // s3 이미지 다운로드
    fn_s3ImageFileDownload(capture_code, product_name) {
        // 파일 확장자 추출
        let file_type = capture_code.substring(capture_code.lastIndexOf(".")+1, capture_code.length);
        const config = {
            headers: {
                'content-type': 'application/json;'
            },
            responseType: 'arraybuffer'
        }
        http.post("/rest/common/kdeal/content/download",
            {
                "capture_code" : capture_code,
                "file_type" : file_type
            },
            config
        )
        .then(res => {
            if (res.data != null) {
                // 상품명(제목) 미입력시 '제목없음'로 다운로드
                if (product_name == '') {
                    product_name = '제목없음';
                }
                let file_name = product_name + '.'+file_type;
                var blob = new Blob([res.data], { type: 'image/'+file_type });

                // 퍼센트 기호(%)가 포함되어 있으면서 올바른 퍼센트 인코딩이 아닌 경우 찾기
                const invalidPercentEncoding = /%(?![0-9A-Fa-f]{2})/;
                if (!invalidPercentEncoding.test(file_name)) {
                    file_name = decodeURI(file_name);
                }

                FileDownload(blob, file_name);
            } else {
                alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
            }
        })
        .catch(error => {
            alert("s3 이미지 다운로드 요청 실패\n고객센터 : 1599-3518");
        });
    }

    render() {
        return (
            <>
                <div id='page-wrapper' className="container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
                    <div id="list_wrap" style={{ justifyContent: "start", alignItems: "start"}}>
                        <section className="list_home_main">
                            <div className="list_home_main_row_1" style={{display:'flex', justifyContent:"space-between", width: "100%", maxWidth:"1200px", alignItems: "baseline"}}>
                                <div style={{display:'flex', marginBottom:"10px"}}>
                                    <h2 className="text_medium" style={{
                                            alignContent: "center",
                                            display: "flex",
                                            marginBottom: 0,
                                            lineHeight: 1.2,
                                            marginRight: "10px"
                                    }}>상세페이지 디자인 목록</h2>
                                    <div style={{
                                            alignContent: "center",
                                            display: "flex"
                                    }}>
                                        {/* <button className="list_btn_1 hover_lightmint" onClick={e => this.onClickUserGuide(e)}>사용자 가이드</button> */}
                                        <button style={{width:"120px"}} className="list_btn_1 hover_mint" onClick={e => this.onClickAddList(e)} >
                                            <img src={plus_img} alt="더하기 아이콘"/>새 디자인 추가
                                        </button>
                                    </div>
                                </div>
                                <div style={{display:'flex', alignItems: "center"}}>
                                    <div style={{position:"relative", width:"300px", height:"auto", display:"flex", alignItems: "center",alignContent: "center"}}>
                                        <div class="btn_search" style={{left: "40px"}}></div>
                                        <input type="text" onChange={(e) => {
                                            // console.log(e.target.value)
                                            this.setState({ keyword: e.target.value })
                                        }} placeholder="디자인 제목을 검색해 보세요." style={{width:"100%", height: "42px", padding: "0px 20px 0px 53px"}} onKeyDown={this._handleKeyDown}></input>
                                        {/* <button className = "greenButton" onClick={this.onClickSearchListButton} style={{width: "45px",height: "35px", margin: 'auto'}}>검색</button> */}
                                    </div>
                                    {/* <div style={{
                                            alignContent: "center",
                                            display: "flex",
                                            marginLeft: "20px"
                                    }}>
                                        {
                                            this.state.kdeal_user_no == ""
                                            ? <select style={{height: "42px", maxWidth:"385px", padding:"0 15px 0 15px", fontSize: "14px", color:"#999"}} value={this.state.kdeal_user_no} onChange={(e) => {
                                                this.setState({
                                                    kdeal_user_no: e.target.value
                                                }, () => {
                                                    this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                                })
                                            }}>
                                                <option value="" disabled>
                                                    업체를 선택해 보세요.
                                                </option>    
                                                {
                                                    this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                                        <option value={obj.user_no}>
                                                            {obj.corp_name_reg_no}
                                                        </option>    
                                                    ))
                                                }
                                            </select>
                                            : <select style={{height: "42px", maxWidth:"385px", padding:"0 15px 0 15px", fontSize: "14px"}} value={this.state.kdeal_user_no} onChange={(e) => {
                                                this.setState({
                                                    kdeal_user_no: e.target.value
                                                }, () => {
                                                    this.loadKdealProduct(this.state.startIdx, this.state.rowcnt, this.state.keyword, this.state.kdeal_user_no);
                                                })
                                            }}>
                                                <option value="">
                                                    전체
                                                </option>    
                                                {
                                                    this.state.shop_list != null && this.state.shop_list.map((obj, idx) => (
                                                        <option value={obj.user_no}>
                                                            {obj.corp_name_reg_no}
                                                        </option>    
                                                    ))
                                                }
                                            </select>
                                        }
                                    </div>     */}
                                </div>    
                            </div>
                            <div className="list_home_main_row_3" style={{width: "100%", maxWidth:"1200px"}}>
                                <table style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                            <td className="list_td" style={{width: "80px"}}>번호</td>
                                            {/* <td className="list_td" style={{width: "260px"}}>업체명</td> */}
                                            <td className="list_td" style={{width: "auto"}}>상세페이지 디자인 제목</td>
                                            <td className="list_td" style={{width: "200px"}}>작성일시</td>
                                            <td className="list_td" style={{width: "200px"}}>다운로드</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.list != null && this.state.list.length > 0
                                        ?   <>
                                            {
                                                this.state.list.map((obj, idx) => (
                                                    <tr>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{(this.state.totalCnt-(idx+(10*(this.state.activePage-1))))}</td>
                                                        {/* <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.corp_name}</td> */}
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.product_name}</td>
                                                        <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}>{obj.reg_date}</td>
                                                        {
                                                            this.state.is_downloaded[idx] == true
                                                            ?   <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}><button type="button" className="btn-auto-w" style={{height:"45px"}} onClick={e => this.onClickContentDownloadBtn(e, idx, obj.product_no, obj.product_name)}>다운로드</button></td>
                                                            :   <td onClick={e => this.onClickProductPageLink(e, obj.product_no)}><button type="button" className="btn-auto-w" style={{height:"45px"}} >다운로드 중...</button></td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                            </>
                                        :
                                            <div style={{width:"1199px"}}>
                                                {
                                                    is_product_loaded == true
                                                    ? "상품이 없습니다."
                                                    : "상품을 불러오는 중입니다."
                                                }
                                            </div>
                                        }
                                    </tbody>
                                </table>
                                <div style={{margin:"15px 0 15px 0", width:"100%"}}>
                                    {
                                        Number(this.state.totalCnt) <= 0
                                        ?   ""
                                        :   <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.rowcnt}
                                                totalItemsCount={this.state.totalCnt}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    };
    
}