import React, { useCallback, useState } from 'react';
import axios from '../modules/api';
import http from '../modules/api';
import * as StyleUtil from '../modules/utils/StyleUtil';
import {Line} from 'react-chartjs-2';
import { NavLink, Link, Prompt } from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";
import plus_img from '../../resources/img/plus.svg';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';
import ToggleButton from '../modules/common/ToggleButton';
import CheckBox from '../modules/common/CheckBox';
import Icon from '../modules/common/Icon';
import icn_clone from '../../resources/img/icn_clone.svg';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

let window_scrollTop; // 스크롤 위치

export class RpEventList_install extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            // 무료 체험 기간 관련 변수
            remain_date: "",
            start_day_of_week: "",
            start_date_format: "",
            is_finish: "",
            end_day_of_week: "",
            end_date_format: "",
            payment_cls_text: "",

            list : [], // 진열 목록
            disabled_del_list : [], // 삭제 불가능한 진열 목록
            checked_rp_list: [], // 선택된 진열 목록
            all_install_yn : true, // 모든 진열이 설치됐는지에 대한 여부(하나라도 설치되지 않았다면 false),
            is_cafe24 : "",
            tutorial_dialog_state : false,

            // 노출 여부 관련 다이얼로그
            confirm_dialog_show_state : false, // 노출 여부 변경 물어보기
            confirm_dialog_content: "",
            confirm_dialog_button_list: [],
            basic_dialog_show_state : false, // 노출 여부 변경 확인
            basic_dialog_content: "",
            basic_dialog_button_list: [],
            delete_dialog_show_state : false, // 삭제 여부 물어보기
            delete_dialog_content: "",
            delete_dialog_button_list: [],
            notice_period_title_content_dialog_show_state : false,
            title_content_dialog_title: "",
            title_content_dialog_content: "",
            title_content_dialog_button_list: [],
            notice_delete_dialog_show_state : false, // 비활성화 삭제 버튼 클릭시 알림
            notice_delete_dialog_content: "",
            notice_delete_dialog_button_list: [],
            notice_period_banner_show_state: false // 진열 이용기간 상단 배너 노출
        }
    }

    componentWillMount() {

    }

	componentDidMount() {
        $(document).ready(function(){
            // 왼쪽 네비바 닫혀있는 경우 열기
            $(".menu_expansion_arrow.cursor-pointer").trigger('click');

            //table의 넓이 계산
            var list_td = document.getElementsByClassName("list_td");
            var list_td_sum = 0;

            for(let i = 0; i < list_td.length; i++){
                list_td_sum = list_td_sum + list_td[i].offsetWidth;
            }

            $(".list_home_main_row_3 table").css({
                "width":list_td_sum
            });
        });

        this.loadFreeDatePeriod(true);
        this.loadRpEventEventSetting(true);

        // 진열 이용기간 상단 배너
        if(sessionStorage.getItem("RP_LIST_OPERATION_BANNER") == "close" || this.getCookie("RP_LIST_OPERATION_BANNER") == "true"){
            this.setState({
                notice_period_banner_show_state: false
            });
            $(".list_home_main_banner").hide();
        }else{
            this.setState({
                notice_period_banner_show_state: true
            });
            $(".list_home_main_banner").show();
        }
    }

    componentDidUpdate() {
    }

    componentWillUnmount(e) {

    }

    // 진열 이용기간 다이얼로그
    loadRpPeriodDialog() {
        if(this.getCookie("RP_LIST_OPERATION_DIALOG")){
            this.setState({
                notice_period_title_content_dialog_show_state: false
            });
        }else{
            if(this.state.is_finish == 0) {
                if(this.state.remain_date > 10) {
                    this.setState({
                        notice_period_title_content_dialog_show_state: false
                    });
                }else{
                    this.showRpPeriodDialog();
                }

            }else {
                this.showRpPeriodDialog();
            }
        }
    }

    // 진열 이용기간 다이얼로그
    showRpPeriodDialog () {
        this.setState({
            notice_period_title_content_dialog_show_state: true,
            title_content_dialog_button_list: [
                {
                    title: "기간 연장하기",
                    type: "1",
                    event_method: () => {
                        this.setState({
                            notice_period_title_content_dialog_show_state : false,
                        }, () => {
                            this.onClicExtendPeriod();
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                },
                {
                    title: "3일동안 보지 않기",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            notice_period_title_content_dialog_show_state: false,
                        }, () => {
                            this.setCookie("RP_LIST_OPERATION_DIALOG", true, 3);
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        });
                    }
                }
            ]
        });
    }

    // 상단 배너 하루동안 보지않기 버튼 클릭
    handleDontShowBannerBtnOnClick () {
        this.setState({
            notice_period_banner_show_state: false,
        }, () => {
            this.setCookie("RP_LIST_OPERATION_BANNER", true, 1);
        });
        $(".list_home_main_banner").hide();
    }

    // 상단 배너 닫기 버튼 클릭
    handleCloseBannerBtnOnClick () {
        this.setState({
            notice_period_banner_show_state: false,
        }, () => {
            sessionStorage.setItem("RP_LIST_OPERATION_BANNER", "close");
        });
        $(".list_home_main_banner").hide();
    }

    setCookie(name, value, day) {
        var date = new Date();
        date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    };

    getCookie(name) {
        var value =document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value? value[2] : null;
    };

    setGaEvent(action_text) {
        ReactGA.event({
             category: "베스트 서비스 (롤링 목록)",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
    }

    // 무료 체험 기간 조회
    async loadFreeDatePeriod(first_load){
        await http.get("/rest/shop/rp/freeDatePeriod")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let start_date = res.data["start_date_format"];
                start_date = start_date.replace("년","-");
                start_date = start_date.replace("월","-");
                start_date = start_date.replace("일","");
                start_date = start_date.replace(/ /gi,"");

                let end_date = res.data["end_date_format"];
                end_date = end_date.replace("년","-");
                end_date = end_date.replace("월","-");
                end_date = end_date.replace("일","");
                end_date = end_date.replace(/ /gi,"");

                let payment_yn = res.data["payment_yn"];
                let payment_cls_text = "";
                if (payment_yn == 'Y') {
                    payment_cls_text = "멤버십";
                } else {
                    payment_cls_text = "무료 체험";
                }

				this.setState({
                    remain_date: res.data["remain_date"],
                    start_day_of_week: res.data["start_day_of_week"],
                    start_date_format: start_date,
                    is_finish: res.data["is_finish"],
                    end_day_of_week: res.data["end_day_of_week"],
                    end_date_format: end_date,
                    payment_yn: res.data["payment_yn"],
                    payment_cls_text: payment_cls_text,
                });

                if(first_load){
                    // 진열 이용기간 다이얼로그
                    this.loadRpPeriodDialog();
                }
			}
		})
		.catch(error => {
		 });
    }
    
    // 진열 목록 데이터 불러오기
    async loadRpEventEventSetting(first_load) {
        await http.get("/rest/shop/rp/list/event/setting/v2?is_cafe24=N")
		.then(res => {
			if (res.data != null && res.data != '') {
                let list = res.data["list"];
                let disabled_del_list = []; // 수량 체크용
                for(var idx in list){
                    // 결제중인 경우, 무료체험중인데 설치한 경우에는 삭제가 불가능하다.
                    // if(list[idx].pay_operated_yn == "Y" || (list[idx].free_operated_yn == "Y" && list[idx].install_yn == "Y")){
                    
                    /* 결제 여부와 상관 없이 설치된 경우에만 삭제 불가 */
                    if(list[idx].install_yn == "Y"){
                        disabled_del_list.push(list[idx]);
                        list[idx].disabled_del = true;
                    }
                }
				this.setState({
                    list : list,
                    disabled_del_list : disabled_del_list,
                    is_cafe24 : "N"
                });

                if(first_load && list.length === 0){
                    window_scrollTop = StyleUtil.onPopupNoneScroll();
                    this.setState({
                        tutorial_dialog_state : true,
                    });
                }
			}
		})
		.catch(error => {
		 });
    }

    // 진열 상세 페이지로 이동
    onClickRpProductPageLink(idx, obj) {
        const {list} = this.state;
        // console.log(this.state.is_finish + " / " + list[idx].operateed_yn);
        // return 0;

        // 이용중일 때만 상세 페이지로 이동 가능
        if(obj.operateed_yn === "Y"){
            this.setGaEvent("목록 클릭 - "+ list[idx].display_name);
            this.props.history.push(
                {
                    pathname: `/shop/rp/event/product/list`,
                    search:`?page_id=`+list[idx].iframe_page_id+`&html_id=`+list[idx].iframe_html_id,
                }	
            )
        } else{
            window_scrollTop = StyleUtil.onPopupNoneScroll();
        
            this.setState({
                basic_dialog_show_state: true,
                basic_dialog_content: "이용 기간이 아닙니다.",
                basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            //팝업 닫기
                            this.setState({ basic_dialog_show_state: false });
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }
                    }
                ]
            });
        }
    }

    // 노출 토글 버튼 클릭 이벤트
    onVisibleCheck(e){
        const id = e.target.id;
        const id_info = id.split('/');
        const idx = id_info[1];
        const iframe_page_id = id_info[2];
        const iframe_html_id = id_info[3];

        const {list} = this.state;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                },
        };
        const url = '/rest/shop/rp/event/display';

        let confirm_dialog_content, basic_dialog_content;
        
        if(list[idx].display_yn == "Y"){
            confirm_dialog_content = "쇼핑몰에 진열을 노출 중지 하시겠습니까?";
            basic_dialog_content = "쇼핑몰에 해당 진열이 더 이상 노출되지<br/>않습니다.";
        } else{
            confirm_dialog_content = "쇼핑몰에 진열을 노출하시겠습니까?";
            basic_dialog_content = "쇼핑몰에 해당 진열이 노출됐습니다.";
        }

        window_scrollTop = StyleUtil.onPopupNoneScroll();
        
        this.setState({
            confirm_dialog_show_state: true,
            confirm_dialog_content: confirm_dialog_content,
            confirm_dialog_button_list: [
                {
                    title: "네",
                    type: "1",
                    event_method: () => {
                        
                        const body = {
                            iframe_page_id : iframe_page_id,
                            iframe_html_id : iframe_html_id,
                            display_yn : list[idx].display_yn == "Y" ? "N" :"Y",
                        }
                        http
                        .post(url, body, config)
                        .then((res) => {
                            this.setState({
                                confirm_dialog_show_state: false,
                            })
                            if (res.data.code == "200") {
                                list[idx].display_yn = list[idx].display_yn == "Y" ? "N" :"Y";
                                this.setState({
                                    list : list,
                                    basic_dialog_show_state: true,
                                    basic_dialog_content: basic_dialog_content,
                                    basic_dialog_button_list: [
                                        {
                                            title: "확인",
                                            type: "1",
                                            event_method: () => {
                                                //팝업 닫기
                                                this.setState({ basic_dialog_show_state: false });
                                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                            }
                                        }
                                    ]
                                });
                            } else {
                                alert(res.data.code + "\n" + res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.setState({ confirm_dialog_show_state: false, });
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            alert(error + '변경 실패.\n관리자에게 문의해주세요(1599-3518)');
                        });

                        this.setGaEvent("수정");
                    }
                },
                {
                    title: "아니요",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            confirm_dialog_show_state: false,
                        });
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ]
        });
    }

    // 기간 연장하기
    onClicExtendPeriod(){
        this.props.history.push(
            {
                pathname: `/shop/qna/form`,
                state: {qna_type_cd: "2104"}
            }	
        )
    }

    // 사용자 가이드
    onClickUserGuide(){
        window.open("https://iclave.notion.site/MD-41912c6f69084ba19fab96b903b08999");
    }

    onClickAddList(e){
        this.setGaEvent("진열 추가 클릭");
        this.props.history.push(
            {
                pathname: `/shop/rp/event/product/list`,
            }	
        )
    }

    // 진열 목록 전체 선택
    checkAllRpEventListOnChange(e) {
        const {list} = this.state;
        let checked_rp_list = [];

        if (e.target.checked) {
            for(var idx in list) {
                // 결제중인 경우, 무료체험중인데 설치한 경우에는 삭제(체크)가 불가능하다.
                if(!list[idx].disabled_del){
                    checked_rp_list.push(list[idx]);
                }
            }
        }

        this.setState({
            checked_rp_list: checked_rp_list,
        });
    }

    // 진열 목록 개별 선택
    checkOneRpEventListOnChange(e) {
        const {list, checked_rp_list} = this.state;
        let idx = e.target.value;

        if(e.target.checked) {
            checked_rp_list.push(list[idx]);
        }else{
            for(var rp in checked_rp_list) {
                if(checked_rp_list[rp].iframe_page_id == list[idx].iframe_page_id && checked_rp_list[rp].iframe_html_id == list[idx].iframe_html_id){
                    checked_rp_list.splice(rp, 1);
                    break;
                }
            }
        }

        this.setState({
            checked_rp_list: checked_rp_list,
        });
    }

    // 진열 목록 다중 삭제
    deleteMultiRpEventListOnClick(e) {
        const {list, checked_rp_list, is_cafe24} = this.state;
        let delete_rp_list = [];

        if(checked_rp_list != null && checked_rp_list.length > 0){
            checked_rp_list.map((obj) => {
                delete_rp_list.push(obj);
            });
        }

        for(var idx in delete_rp_list){
            console.log(delete_rp_list[idx])
        }

        const config = {
            headers: {'Content-Type': 'application/json'}
        };
        const url = '/rest/shop/rp/event/list/delete';
        const body = {
            is_cafe24 : is_cafe24,
            list : delete_rp_list
        };

        if (delete_rp_list != null && delete_rp_list.length > 0) {
            this.setState({
                delete_dialog_show_state: true,
                delete_dialog_content: "선택하신 진열을 삭제하시겠습니까?",
                delete_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            http
                                .post(url, body, config)
                                .then((res) => {
                                    if (res.data.code == "200") {
                                        this.setState({
                                            delete_dialog_show_state: false,
                                            checked_rp_list: [],
                                        }, () => {
                                            this.setState({
                                                basic_dialog_show_state : true,
                                                basic_dialog_content: "삭제가 완료되었습니다.",
                                                basic_dialog_button_list: [
                                                    {
                                                        title: "확인",
                                                        type: "1",
                                                        event_method: () => {
                                                            //팝업 닫기
                                                            this.setState({ basic_dialog_show_state: false });
                                                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                                        }
                                                    }
                                                ],
                                            });

                                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                            this.loadFreeDatePeriod();
                                            this.loadRpEventEventSetting();
                                        });

                                    } else if (res.data.code == "400" || res.data.code == "500") {
                                        alert(res.data.msg);
                                        this.setState({
                                            delete_dialog_show_state: false
                                        });

                                    } else {
                                        alert("삭제에 실패하였습니다.");
                                        this.setState({
                                            delete_dialog_show_state: false
                                        });
                                    }
                                })
                                .catch((error) => {
                                    alert("삭제에 실패하였습니다.");
                                    this.setState({
                                        delete_dialog_show_state: false
                                    });
                                    console.log(error);
                                });
                        }
                    },
                    {
                        title: "취소",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                delete_dialog_show_state: false
                            });
                        }
                    }
                ]
            });
        }else {
            alert("삭제할 진열을 선택해주세요.");
        }
    }

    // 진열 목록 개별 삭제
    deleteOneRpEventListOnClick(e, obj) {
        const {is_cafe24} = this.state;
        let iframe_page_id = obj.iframe_page_id;
        let iframe_html_id = obj.iframe_html_id;

        console.log("iframe_page_id: " + iframe_page_id)
        console.log("iframe_html_id: " + iframe_html_id)

        const config = {
            headers: {'Content-Type': 'application/json'}
        };
        const url = '/rest/shop/rp/event/delete';
        const body = {
            is_cafe24 : is_cafe24,
            iframe_page_id : iframe_page_id,
            iframe_html_id : iframe_html_id
        };

        if (iframe_page_id != null && iframe_page_id != "" && iframe_html_id != null && iframe_html_id != "") {
            this.setState({
                delete_dialog_show_state: true,
                delete_dialog_content: "이 진열을 삭제하시겠습니까?",
                delete_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            http
                                .post(url, body, config)
                                .then((res) => {
                                    if (res.data.code == "200") {
                                        this.setState({
                                            delete_dialog_show_state: false,
                                            checked_rp_list: [],
                                        }, () => {
                                            this.setState({
                                                basic_dialog_show_state : true,
                                                basic_dialog_content: "삭제가 완료되었습니다.",
                                                basic_dialog_button_list: [
                                                    {
                                                        title: "확인",
                                                        type: "1",
                                                        event_method: () => {
                                                            //팝업 닫기
                                                            this.setState({ basic_dialog_show_state: false });
                                                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                                        }
                                                    }
                                                ],
                                            });

                                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                            this.loadFreeDatePeriod();
                                            this.loadRpEventEventSetting();
                                        });

                                    } else if (res.data.code == "405" || res.data.code == "400") {
                                        alert(res.data.msg);
                                        this.setState({
                                            delete_dialog_show_state: false
                                        });

                                    } else {
                                        alert("삭제에 실패하였습니다.");
                                        this.setState({
                                            delete_dialog_show_state: false
                                        });
                                    }
                                })
                                .catch((error) => {
                                    alert("삭제에 실패하였습니다.");
                                    this.setState({
                                        delete_dialog_show_state: false
                                    });
                                });
                        }
                    },
                    {
                        title: "취소",
                        type: "0",
                        event_method: () => {
                            this.setState({
                                delete_dialog_show_state: false
                            });
                        }
                    }
                ]
            });
        }
    }

    // 비활성화 삭제 버튼 클릭시 알림
    noticeDeleteRpEventListOnClick() {
        this.setState({
            notice_delete_dialog_show_state : true,
            // notice_delete_dialog_content: "설치중이거나 결제이용중인 진열은<br/>삭제할 수 없습니다. 삭제를 원하시는 경우,<br/>문의하기 버튼을 눌러주세요.",
            notice_delete_dialog_content: "설치된 진열은 삭제할 수 없습니다.<br/>삭제를 원하시는 경우, 문의하기 버튼을 눌러주세요.",
            notice_delete_dialog_button_list: [
                {
                    title: "문의하기",
                    type: "1",
                    event_method: () => {
                        this.props.history.push(
                            {
                                pathname: `/shop/qna/form`
                            }
                        )
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                },
                {
                    title: "닫기",
                    type: "0",
                    event_method: () => {
                        this.setState({
                            notice_delete_dialog_show_state: false,
                        });
                        StyleUtil.clearPopupNoneScroll(window_scrollTop);
                    }
                }
            ],
        });
    }

    cloneRpEventListOnClick (e, obj) {
		let iframe_page_id = obj.iframe_page_id;
		let iframe_html_id = obj.iframe_html_id;
		console.log("iframe_page_id: " + iframe_page_id);
		console.log("iframe_html_id: " + iframe_html_id);

		const config = {
			headers: {'Content-Type': 'application/json'}
		};
		const url = '/rest/shop/rp/clone';
		const body = {
			user_seq : obj.user_seq,
			shop_no : obj.shop_no,
			iframe_page_id : iframe_page_id,
			iframe_html_id : iframe_html_id,
		};

		if (
            (iframe_page_id != null && iframe_page_id != "")
            && (iframe_html_id != null && iframe_html_id != "")
        ) {
			this.setState({
				delete_dialog_show_state: true,
				delete_dialog_content: "이 진열을 복제하시겠습니까?",
				delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
                            this.setState({
                                delete_dialog_show_state: false,
                                show_save_loading_dialog: true,
                                loading_dialog_message: "진열을 복제하고 있습니다."
                            });
							http
								.post(url, body, config)
								.then((res) => {
									if (res.data.code == "200") {
										this.setState({
											delete_dialog_show_state: false,
											checked_auto_event_list: [],
                                            show_save_loading_dialog: false, 
										}, () => {

											this.setState({
												basic_dialog_show_state : true,
												basic_dialog_content: "복제가 완료되었습니다.",
												basic_dialog_button_list: [
													{
														title: "확인",
														type: "1",
														event_method: () => {
															this.setState({ 
                                                                basic_dialog_show_state: false,
                                                            });
														}
													}
												],
											});
											this.loadRpEventEventSetting();
                                            // 스크롤탑
                                            window.scrollTo(0, 0);
										});

									} else if (res.data.code == "401" || res.data.code == "403") {
										alert(res.data.msg);
										this.setState({
											delete_dialog_show_state: false,
											show_save_loading_dialog: false,
										});

									} else {
										alert("복제에 실패하였습니다.");
										this.setState({
											delete_dialog_show_state: false,
											show_save_loading_dialog: false,
										});
									}
								})
								.catch((error) => {
									alert("복제에 실패하였습니다.");
									this.setState({
										delete_dialog_show_state: false,
										show_save_loading_dialog: false,
									});
								});
						}
					},
					{
						title: "취소",
						type: "0",
						event_method: () => {
							this.setState({
								delete_dialog_show_state: false
							});
						}
					}
				]
			});
		}
	}

    renderDaliyStandardAutoDisplay(obj) {
        return (
            <>  
                <div>일별</div>
                {
                    obj.event_cycle
                    ? <div>{obj.event_cycle+"일 마다"}</div>
                    : null
                }
                {
                    obj.event_time
                    ? <div>{obj.event_time+"시에"}</div>
                    : null
                }
                {
                    obj.next_auto_run_date
                    ? <div>{"예약일 " + obj.next_auto_run_date}</div>
                    : null
                }
            </>
        )
    }

    renderWeeklyStandardAutoDisplay(obj) {
        return (
            <>
                <div>요일별</div>
                {
                    obj.weekday_list
                    ? <div>
                        {
                            obj.weekday_list.split(",").length == 7 
                            ? 
                                "전체요일 마다"
                            : 
                                obj.weekday_list
                                .replaceAll(",", "/")
                                .replace("0", "월")
                                .replace("1", "화")
                                .replace("2", "수")
                                .replace("3", "목")
                                .replace("4", "금")
                                .replace("5", "토")
                                .replace("6", "일")
                                +" 마다"
                        }
                        </div>
                    : null
                }
                {
                    obj.event_time
                    ? <div>{obj.event_time+"시에"}</div>
                    : null
                }
            </>
        )
    }

    renderRealTimeStandardAutoDisplay(obj) {
        return (
            <>
                <div>실시간</div>
                {
                    obj.weekday_list
                    ? <div>
                        {
                            obj.weekday_list.split(",").length == 7 
                            ? 
                                "전체요일 마다" 
                            : 
                                obj.weekday_list
                                .replaceAll(",", "/")
                                .replace("0", "월")
                                .replace("1", "화")
                                .replace("2", "수")
                                .replace("3", "목")
                                .replace("4", "금")
                                .replace("5", "토")
                                .replace("6", "일")
                                +" 마다"
                        }
                        </div>
                    : null
                }
                {
                    obj.real_time_cycle
                    ? <div>
                        {
                            Number(obj.real_time_cycle) < 60
                            ? obj.real_time_cycle+"분 마다"
                            :  (() => {
                                const hours = Math.floor(obj.real_time_cycle / 60);
                                const minutes = obj.real_time_cycle % 60;
                                return minutes === 0 
                                  ? `${hours}시간 마다` 
                                  : `${hours}시간 ${minutes}분 마다`;
                              })()
                        }
                        </div>
                    : null
                }
                {
                    obj.real_time_start_hour && obj.real_time_end_hour
                    ? <div>
                        {
                           obj.real_time_start_hour+"시~"+obj.real_time_end_hour+"시까지"
                        }
                        </div>
                    : null
                }
            </>
        )
    }

    render() {
        return (
            <>
                <div id='common-page-wrapper' className="style_guide_v2">
                    <div id="list_wrap">
                        <div className="list_home_main_banner">
                            <div className="txt_banner_wrap">
                                <span className="i_notice_banner">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                    </svg>
                                </span>
                                <h3 className="text_extrasmall txt_banner_1" style={{marginBottom: 0}}>
                                    {this.state.is_finish == 0 ? this.state.remain_date + "일 뒤 " + this.state.payment_cls_text + "이 종료됩니다. " : this.state.payment_cls_text + "이 종료되었습니다. "}
                                </h3>
                                <h3 className="text_extrasmall txt_banner_2">
                                    ({this.state.payment_cls_text} 기간
                                    : {this.state.start_date_format} ~ {this.state.end_date_format})
                                </h3>
                                <div className="wrap_btn_period_banner">
                                    <button className="btn_period_banner" onClick={() => this.onClicExtendPeriod()}>기간 연장하기</button>
                                    <button className="btn_period_banner" onClick={() => this.handleDontShowBannerBtnOnClick()}>하루동안 보지 않기</button>
                                </div>
                                <button className="btn_close_banner" onClick={() => this.handleCloseBannerBtnOnClick()}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.2847 14.2158C15.4255 14.358 15.5045 14.5501 15.5045 14.7502C15.5045 14.9504 15.4255 15.1424 15.2847 15.2846C15.1413 15.4232 14.9497 15.5007 14.7503 15.5007C14.5509 15.5007 14.3593 15.4232 14.2159 15.2846L8.00031 9.05959L1.78469 15.2846C1.64133 15.4232 1.44972 15.5007 1.25031 15.5007C1.0509 15.5007 0.859293 15.4232 0.715938 15.2846C0.575101 15.1424 0.496094 14.9504 0.496094 14.7502C0.496094 14.5501 0.575101 14.358 0.715938 14.2158L6.94094 8.00022L0.715938 1.78459C0.59634 1.63887 0.535221 1.45387 0.544469 1.26558C0.553717 1.07729 0.632669 0.899174 0.76597 0.765873C0.899272 0.632572 1.07739 0.553619 1.26568 0.544372C1.45397 0.535124 1.63897 0.596243 1.78469 0.715841L8.00031 6.94084L14.2159 0.715841C14.3617 0.596243 14.5467 0.535124 14.7349 0.544372C14.9232 0.553619 15.1014 0.632572 15.2347 0.765873C15.368 0.899174 15.4469 1.07729 15.4562 1.26558C15.4654 1.45387 15.4043 1.63887 15.2847 1.78459L9.05969 8.00022L15.2847 14.2158Z" fill="#333333"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <section className="list_home_main">
                            <div className="list_home_main_row_1">
                                <h2 className="text_regular">설치형 진열 목록</h2>
                                <div className="wrap_list_btn_del_rp">
                                    {/*<button className="list_btn_1 hover_lightmint" onClick={() => this.onClickUserGuide()}>사용자 가이드</button>*/}
                                    <button className="list_btn_del_rp" onClick={() => this.deleteMultiRpEventListOnClick()}>진열 삭제</button>
                                    <button className="list_btn_1 hover_mint add_event_list" onClick={e => this.onClickAddList(e)} >
                                        <img src={plus_img} alt="더하기 아이콘"/>진열 추가
                                    </button>
                                </div>
                            </div>
                            {/*<div className="list_home_main_row_2">*/}
                            {/*    <div>*/}
                            {/*        <span class="i_notice">*/}
                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">*/}
                            {/*                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>*/}
                            {/*            </svg>*/}
                            {/*        </span>*/}
                            {/*        <h3 className="text_extrasmall" style={{marginBottom : 0}}>*/}
                            {/*            { this.state.is_finish == 0 ? this.state.remain_date+"일 뒤 "+this.state.payment_cls_text+"이 종료됩니다. " : this.state.payment_cls_text+"이 종료되었습니다. " }*/}
                            {/*            <br/>*/}
                            {/*            ({this.state.payment_cls_text} 기간 : {this.state.start_date_format} ~ {this.state.end_date_format})*/}
                            {/*        </h3>*/}
                            {/*    </div>*/}
                            {/*    { this.state.all_install_yn*/}
                            {/*    ?   ""*/}
                            {/*    :*/}
                            {/*        <>*/}
                            {/*            <button className="list_btn_2 hover_dark" onClick={() => this.onClicExtendPeriod()}>*/}
                            {/*                기간 연장하기*/}
                            {/*            </button>*/}
                            {/*        </>*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <div className="list_home_main_row_3 widget_rp_list">
                                <table>
                                    <thead>
                                        <tr>
                                            <td className="list_td">
                                                <CheckBox
                                                    id={"list_home_main_checkbox_all"}
                                                    checked={this.state.checked_rp_list.length > 0 && ((this.state.checked_rp_list.length + this.state.disabled_del_list.length) == this.state.list.length)}
                                                    onChangeCheck={this.checkAllRpEventListOnChange.bind(this)}
                                                />
                                            </td>
                                            <td className="list_td">번호</td>
                                            <td className="list_td">진열 제목</td>
                                            <td className="list_td">진열 변경일</td>
                                            <td className="list_td">자동변경 예약일정</td>
                                            <td className="list_td">설정상품수</td>
                                            <td className="list_td">변경상품수</td>
                                            <td className="list_td">동작방식</td>
                                            <td className="list_td">디자인</td>
                                            <td className="list_td">등록일</td>
                                            <td className="list_td">진열방식</td>
                                            <td className="list_td">쇼핑몰</td>
                                            <td className="list_td">페이지</td>
                                            <td className="list_td">설치/진열 상태</td>
                                            {/* <td className="list_td">이용 상태</td> */}
                                            <td className="list_td">진열 복제</td>
                                            {/* <td className="list_td">진열 삭제</td> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.list != null && this.state.list.length > 0
                                        ?   <>
                                            {
                                                this.state.list.map((obj, idx) => (
                                                    <tr key={obj.iframe_page_id+'_'+obj.iframe_html_id}>
                                                        <td>
                                                            <CheckBox
                                                                id={"list_home_main_checkbox_" + (Number(idx))}
                                                                value={idx}
                                                                checked={this.state.checked_rp_list.find(rp => rp.iframe_page_id == obj.iframe_page_id && rp.iframe_html_id == obj.iframe_html_id) != null}
                                                                disabled={obj.disabled_del}
                                                                onChangeCheck={obj.disabled_del ? null : this.checkOneRpEventListOnChange.bind(this)}
                                                            />
                                                        </td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{this.state.list.length-idx}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}><span>{obj.display_name}</span></td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.last_mod_date}</td>
                                                        
                                                        <td className="rp_standard_auto_display " onClick={() => this.onClickRpProductPageLink(idx, obj)}>
                                                            {
                                                                obj.auto_create_yn == "Y"
                                                                ?   obj.event_cycle_type == "A8001" 
                                                                    ?   // 일별
                                                                        this.renderDaliyStandardAutoDisplay(obj)
                                                                    :   obj.event_cycle_type == "A8002"
                                                                        ?   this.renderWeeklyStandardAutoDisplay(obj)
                                                                        :   obj.event_cycle_type == "A8003"
                                                                            ?   this.renderRealTimeStandardAutoDisplay(obj)
                                                                            :   // 일별
                                                                                this.renderDaliyStandardAutoDisplay(obj)
                                                                :   null          
                                                            }
                                                        </td>

                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.product_cnt}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.last_event_product_cnt}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.auto_create_yn_name}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>
                                                            <img src={obj.template_image_url}/>
                                                        </td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.reg_date}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.display_type}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.platform_type_name}</td>
                                                        <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>{obj.iframe_page_simple_name}</td>
                                                        { obj.install_yn == "Y"
                                                            ?   <td>
                                                                <ToggleButton
                                                                    id={"toggle/"+idx+"/"+obj.iframe_page_id+"/"+obj.iframe_html_id}
                                                                    checked={obj.display_yn == "Y"}
                                                                    disabled={obj.operateed_yn != "Y"}
                                                                    //disabled={this.state.is_finish != 0}
                                                                    onClickOption={obj.operateed_yn == "Y" ? this.onVisibleCheck.bind(this) : null}
                                                                />
                                                            </td>
                                                            :   <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>설치 전</td>
                                                        }
                                                        {/* {obj.pay_operated_yn === "Y" ?
                                                            <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>
                                                                <font style={{textAlign:"center", color:"#10CFC9"}}>결제 이용 중</font>
                                                            </td>
                                                            :
                                                            obj.free_operated_yn === "Y" ?
                                                                <td onClick={() => this.onClickRpProductPageLink(idx, obj)}>
                                                                    <font style={{textAlign:"center", color:"#10CFC9"}}>무료 체험 중</font>
                                                                </td>
                                                                :
                                                                obj.request_type == null ?
                                                                    <td>
                                                                        <font style={{textAlign:"center", color:"#B7BDC3"}}>무료 체험 종료</font>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <font style={{textAlign:"center", color:"#B7BDC3"}}>{obj.code_name}</font>
                                                                    </td>
                                                        } */}
                                                        <td>
                                                            <Icon className={"btn_clone_auto_event"} text={"진열 복제"} children={<img src={icn_clone} alt={"진열 복제"}/>} onClick={(e) => {this.cloneRpEventListOnClick(e, obj)}}>
															</Icon>
                                                        </td>
                                                        {/* <td>
                                                            {obj.disabled_del
                                                            ?   <button className="btn_del_rp_disabled" onClick={(e) => {this.noticeDeleteRpEventListOnClick()}}>삭제</button>
                                                            :   <button className="btn_del_rp" onClick={(e) => {this.deleteOneRpEventListOnClick(e, obj)}}>삭제</button>
                                                            }
                                                        </td> */}
                                                    </tr>
                                                ))
                                            }
                                            </>
                                        :
                                            ""
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="warning_del_rp_setting">
                                {/* <p><i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;설치중이거나 결제이용중인 진열은 삭제할 수 없습니다.</p> */}
                                <p><i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;설치된 진열은 삭제할 수 없습니다.</p>
                            </div>
                        </section>
                    </div>
                </div>
                {
					(this.state.confirm_dialog_show_state === true)
					? <ConfirmDialogV2
						content={this.state.confirm_dialog_content}
						button_list={this.state.confirm_dialog_button_list}
						popup_close={() => {
                            this.setState({confirm_dialog_show_state: false});
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }}
					/>
					: ''
				}
                {
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
					/>
					: ''
				}
                {
                    (this.state.delete_dialog_show_state === true)
                        ? <ConfirmDialogV2
                            content={this.state.delete_dialog_content}
                            button_list={this.state.delete_dialog_button_list}
                            popup_close={() => {
                                this.setState({delete_dialog_show_state: false});
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }}
                        />
                        : ''
                }
                {
                    (this.state.notice_period_title_content_dialog_show_state === true)
                        ? <TitleContentDialogV2
                            title={ this.state.is_finish === 0 ? this.state.remain_date +"일 뒤 "+ this.state.payment_cls_text+"이 종료됩니다. <br/> 기간을 연장하시겠습니까?"
                                                                : this.state.payment_cls_text+"이 종료되었습니다. <br/> 기간을 연장하시겠습니까?" }
                            content={this.state.payment_cls_text + " 기간<br/>" +  this.state.start_date_format + " ~ " + this.state.end_date_format}
                            button_list={this.state.title_content_dialog_button_list}
                            popup_close={() => {
                                this.setState({notice_period_title_content_dialog_show_state: false});
                            }}
                        />
                        : ''
                }
                {
                    (this.state.notice_delete_dialog_show_state === true)
                        ? <TitleContentDialogV2
                            content={this.state.notice_delete_dialog_content}
                            button_list={this.state.notice_delete_dialog_button_list}
                            popup_close={() => {
                                this.setState({notice_delete_dialog_show_state: false});
                                StyleUtil.clearPopupNoneScroll(window_scrollTop);
                            }}
                        />
                        : ''
                }
                {this.state.tutorial_dialog_state &&
					<TitleContentDialogV2
						title={"위젯 자동진열 설치등록"}
						content={"위젯디자인을 선택해서 설정하신 후<br/>상품 상세페이지, 장바구니 등 새로운 페이지에<br/>자동상품진열을 설치해서 사용하실 수 있습니다.<br/><br/>설치전에는 쇼핑몰에 적용되지 않습니다."}
						button_list={[
                            {
                                title: "기능 둘러보기",
                                type: "1",
                                event_method: () => {
                                    this.onClickAddList();
                                    //팝업 닫기
                                    this.setState({
                                        tutorial_dialog_state: false,
                                        notice_period_title_content_dialog_show_state : false,
                                    });
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }
                            },
                            {
                                title: "닫기",
                                type: "0",
                                event_method: () => {
                                    //팝업 닫기
                                    this.setState({ tutorial_dialog_state: false });
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }
                            }
                        ]}
                        popup_close={() => {
                            this.setState({ tutorial_dialog_state: false });
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }}
					/>
				}
                {this.state.show_save_loading_dialog &&
                    <SaveDialogV2
                        content={this.state.loading_dialog_message}
                        button_list={[{
                            title : "확인",
                            display_yn : "N",
                            event_method : () => { this.handleToggle("show_save_loading_dialog") }
                        }]}
                    />
                }
            </>
        )
    };
    
}